import request from 'utils/request'

const url = 'excel_tables/'

const urlWithId = id => url + id + '/'

// 获取左侧总表
export function fetchExcelTables(params) {
  return request({
    method: 'GET',
    url,
    params
  })
}

export function addExcelTable(filename) {
  return request({
    method: 'POST',
    url,
    data: { filename }
  })
}

// 获取选择框“第几板”列表
export function fetchBoardOptions(id, params) {
  return request({
    method: 'GET',
    url: urlWithId(id),
    params
  })
}

export function modifyExcelTable(filename, id) {
  return request({
    method: 'PUT',
    url: urlWithId(id),
    data: { filename }
  })
}

export function deleteExcelTable(id) {
  return request({
    method: 'DELETE',
    url: urlWithId(id),
  })
}

export function exportExcelTable(id) {
  return request({
    method: 'POST',
    url: urlWithId(id) + 'export/',
    data: { url: true }
  })
}

// 获取表格数据
export function fetchExcelTableData(id, serial_number) {
  return request({
    method: 'GET',
    url: urlWithId(id) + 'get_serial_number/',
    params: { serial_number }
  })
}

// 版号
export function addSerialNum(serial_number, id) {
  return request({
    method: 'POST',
    url: urlWithId(id) + 'add_serial_number/',
    data: { serial_number }
  })
}

// 更新版号
export function updateBoardId(old_serial_number, new_serial_number, id) {
  return request({
    method: 'PUT',
    url: urlWithId(id) + 'update_serial_number/',
    data: { old_serial_number, new_serial_number }
  })
}

export function addCells(data, id) {
  return request({
    method: 'POST',
    url: urlWithId(id) + 'submit_cells/',
    data
  })
}

export function fetchCells(params) {
  return request({
    method: 'GET',
    url: 'cells/',
    params
  })
}

// 入库
export function storageExcelTable(id) {
  return request({
    method: 'POST',
    url: urlWithId(id) + 'storage/'
  })
}


export function deleteBoard(id, boardId) {
  return request({
    method: 'DELETE',
    url: urlWithId(id) + 'delete_serial_number/',
    data: { serial_number: boardId }
  })
}