import request from 'utils/request'

const mainUrl = 'system_users/'

const urlWithId = id => `${mainUrl}${id}/`

export function fetchUsers(id, params) {
  return request({
    url: urlWithId(id) + 'users/',
    method: 'GET',
    params
  })
}

export function fetchSystemUsers(query) {
  return request({
    url: mainUrl,
    method: 'GET',
    params: query
  })
}
export function fetchUserDetail(id) {
  return request({
    url: urlWithId(id),
    method: 'GET'
  })
}

export function addUser(data) {
  return request({
    url: mainUrl,
    method: 'post',
    data
  })
}
export function modifyUserByPatch(id, data) {
  return request({
    url: urlWithId(id),
    method: 'PATCH',
    data
  })
}
export function bulkDeleteUsers(data) {
  return request({
    url: `${mainUrl}bulk_delete/`,
    method: 'POST',
    data
  })
}
export function userActivation(id, data) {
  return request({
    url: `${mainUrl}${id}/is_active/`,
    method: 'post',
    data
  })
}
export function userResetPassword(id, password) {
  return request({
    url: `${mainUrl}${id}/reset_password/`,
    method: 'POST',
    data: { password }
  })
}