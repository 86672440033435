/* eslint-disable react-hooks/exhaustive-deps */
// 数据统计弹窗
import { Button, DatePicker, Select } from 'antd'
import { fetchStatisticsSample, postStatisticsSample } from 'api/sample'
import { fetchUsers } from 'api/set/user'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getAuthInfo, getLastInMonth } from 'utils'
import './index.scss'

const { Option } = Select

export default function () {
  const year = new Date().getFullYear()
  const month = new Date().getMonth() + 1

  const [search, setSearch] = useState('')
  const [users, setUsers] = useState([])
  const [user_id, setUser_id] = useState(undefined)
  const [min_sampling_time, setMin_sampling_time] = useState(`${year}-${month}-01`)
  const [max_sampling_time, setMax_sampling_time] = useState(`${year}-${month}-${getLastInMonth()} 23:59`)
  const [single, setSingle] = useState(0)
  const [mix, setMix] = useState(0)
  const [mix_total, setMix_total] = useState(0)
  const [env, setEnv] = useState(0)
  const [perm, setPerm] = useState('person')
  const [exportLoading, setExportLoading] = useState(false)

  useEffect(() => {
    getStatisticsSample()
  }, [max_sampling_time, user_id, perm])

  useEffect(() => {
    getUsers()
  }, [search])

  const exportStatistics = () => {
    setExportLoading(true)
    postStatisticsSample({
      min_sampling_time,
      max_sampling_time,
      user_id,
      perm
    }).then(({ data }) => {
      window.open(data.url)
    }).finally(() => {
      setExportLoading(false)
    })
  }

  const handleDatePicker = e => {
    const yearAndMonth = e.toISOString().slice(0, 8)
    const selectMonth = new Date(e).getMonth() + 1
    setMin_sampling_time(yearAndMonth + '01')
    setMax_sampling_time(yearAndMonth + getLastInMonth(selectMonth) + ' 23:59')
  }

  const getUsers = () => {
    const userId = getAuthInfo().id
    fetchUsers(userId, { search }).then(({ data }) => {
      setUsers(data.results)
    })
  }

  const getStatisticsSample = () => {
    fetchStatisticsSample({
      min_sampling_time,
      max_sampling_time,
      user_id,
      perm
    }).then(({ data }) => {
      const { single, mix, mix_total, env } = data
      setSingle(single)
      setMix(mix)
      setMix_total(mix_total)
      setEnv(env)
    })
  }

  const userOptions = users.map(i =>
    <Option key={i.id} value={i.id}>{i.nickname}</Option>)

  return <div className='export-statistics'>
    <Button
      className='export-btn'
      type='primary'
      loading={exportLoading}
      onClick={exportStatistics}
    >
      导出
    </Button>
    <div>
      账号：
      <Select
        showSearch
        allowClear
        filterOption={false}
        placeholder='请选择账号'
        onChange={e => setUser_id(e)}
        onSearch={e => setSearch(e)}
      >
        {userOptions}
      </Select>
      <span className='date-picker-w'>
        采样时间：
        <DatePicker
          picker='month'
          defaultValue={moment(new Date())}
          onChange={handleDatePicker}
        />
      </span>
      范围：
      <Select
        allowClear
        placeholder='请选择账号'
        value={perm}
        onChange={e => setPerm(e)}
      >
        <Option value='person'>个人</Option>
        <Option value='group'>所有</Option>
      </Select>
    </div>
    <div className='statistics-w'>
      <div>
        <div className='title'>单采</div>
        <div><span>样本量：</span>{single}</div>
      </div>
      <div>
        <div className='title'>混采</div>
        <div><span>样本量：</span>{mix}</div>
        <div><span>管数：</span>{mix_total}</div>
      </div>
      <div>
        <div className='title'>环境样本</div>
        <div><span>样本量：</span>{env}</div>
      </div>
    </div>
  </div>
}