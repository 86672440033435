/* eslint-disable react-hooks/exhaustive-deps */
import { fetchExcelTableData } from 'api/excelTable'
import React, { useEffect, useState } from "react"

import './index.scss'

let temp = []

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
export default function (props) {

  const { sheetId, boardId, isModalOpen } = props

  const [tableData, setTableData] = useState([])

  const getExcelTableData = () =>
    fetchExcelTableData(sheetId, boardId).then(({ data }) => {
      const { cells } = data
      for (const iterator of cells) {
        const { row, column } = iterator
        temp[row - 1][column - 1] = iterator
      }
      setTableData(JSON.parse(JSON.stringify(temp)))
    })

  useEffect(() => {
    for (let i = 0; i < 8; i++) {
      temp[i] = []
      for (let j = 0; j < 12; j++) {
        temp[i][j] = { number: '' }
      }
    }
    if (!boardId) {
      setTableData(JSON.parse(JSON.stringify(temp)))
      return
    }
    getExcelTableData()
  }, [sheetId, boardId, isModalOpen])

  const renderTb = (letter, column) => {
    return <div className='tb' key={letter}>
      <div className='row-h'>{letter}</div>
      {/* 渲染每一行的每一列 */}
      {column && column.map((item1, index1) => {
        const { status, number, labels, double } = item1
        let className = labels
        // 入库失败
        if (status === 20) {
          className = 'storage-fail'
        }
        // 条码重复
        if (double) {
          className = 'code-repeat '
        }
        return <div key={index1} className={className}>
          {number}
        </div>
      }
      )}
    </div>
  }

  return <div className='excel-table'>
    <div className='title'>第{boardId}板</div>
    <div className='table'>
      <div className='th'>
        <div className='row-h'></div>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
        <div>6</div>
        <div>7</div>
        <div>8</div>
        <div>9</div>
        <div>10</div>
        <div>11</div>
        <div>12</div>
      </div>
      {/* 渲染 A 到 H 8 行 */}
      {letters.map((item, index) => renderTb(item, tableData[index]))}
    </div>
  </div>
}