import request from '../../../utils/request.js'

const url = 'groups/'

export function fetchGroups(query) {
  return request({
    url,
    method: 'GET',
    params: query,
  })
}

export function addGroup(data) {
  return request({
    url,
    method: 'POST',
    data
  })
}

export const updateGroup = (id, data) => request({
  url: `${url}${id}/`,
  method: 'PUT',
  data
})

export const deleteGroup = id => request({
  url: `${url}${id}/`,
  method: 'DELETE',
})