/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { message, Table } from 'antd'

import EvenSearch from 'components/search'
import EvenPagination from 'components/pagination'

import {
  fetchAccountLog,
  fetchLogsList,
} from 'api/logs/logs'
import { downloadOrDeleteSample, fetchUploadLog, postUploadStatistics } from 'api/sample'
import { confirmMessage } from 'utils'

export default function LogTable(props) {

  const { whichLog } = props

  const [isSearchBtnLoading, setIsSearchBtnLoading] = useState(false)

  const [isTableLoading, setIsIsTableLoading] = useState(false)
  const [table, setTable] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [allCount, setAllCount] = useState(0)
  const [searchTxt, setSearchTxt] = useState('')

  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, searchTxt])

  const listQuery = () => {
    let tempObj = {
      page: `${currentPage}`,
      page_size: `${pageSize}`,
      search: `${searchTxt}`,
    }
    if (!whichLog) {
      tempObj.object_model = 'sample'
      tempObj.object_id = `${props.objectId}`
    }
    return tempObj
  }

  const getList = async () => {
    setIsIsTableLoading(true)
    let fn = prams => fetchLogsList(prams)
    switch (whichLog) {
      case 'uploadLog':
        fn = p => fetchUploadLog(p)
        break
      case 'uploadStatistics':
        fn = p => {
          p.action = 'upload_statistics'
          return fetchUploadLog(p)
        }
        break
      case 'accountLog':
        fn = p => fetchAccountLog(p)
        break
      default:
    }
    // if (whichLog === 'uploadLog') {
    //   fn = p => fetchUploadLog(p)
    // } else if (whichLog === 'uploadStatistics') {
    //   fn = p => {
    //     p.action = 'upload_statistics'
    //     return fetchUploadLog(p)
    //   }
    // }
    await fn(listQuery()).then(response => {
      setIsIsTableLoading(false)
      setTable(response.data.results)
      setAllCount(response.data.count)

      if (isSearchBtnLoading) {
        setIsSearchBtnLoading(false)
      }
    })
  }

  const onSearchChange = (value) => {
    setIsSearchBtnLoading(true)
    if (value === searchTxt) {
      setCurrentPage(1)
      getList().then(() => {
        setIsSearchBtnLoading(false)
      })
    } else {
      setCurrentPage(1)
      setSearchTxt(value)
    }
  }
  const onShowSizeChange = (pageSize) => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }
  const onPageChange = (name, page, pageSize) => {
    console.log(name, page, pageSize)
    setCurrentPage(page)
  }

  const handleSampleSerialNumber = (serial_number, log_id, action) => {
    const submitData = { serial_number, action, log_id }
    const isDownload = action === 'download'
    const fn = data => downloadOrDeleteSample(data).then(res => {
      if (isDownload) {
        window.open(res.data.url)
      } else if (res.status === 200) {
        message.success('删除成功！')
        getList()
      }
    })

    if (isDownload) {
      submitData.url = true
      fn(submitData)
    } else {
      confirmMessage(() => fn(submitData))
    }
  }

  const downloadUploadStatistics = id => {
    postUploadStatistics(id).then(({ data }) => {
      window.open(data.url)
    })
  }


  let currentColumns = [
    { dataIndex: 'user', title: '操作用户' },
    { dataIndex: 'ip', title: 'IP地址' },
    { dataIndex: 'created_time', title: '操作时间' },
    { dataIndex: 'action', title: '操作类型' },
    { dataIndex: 'desc', title: '具体操作' },
  ]
  if (whichLog === 'uploadLog') {
    currentColumns = [
      {
        dataIndex: 'details', title: '批次编号',
        render(text) {
          return text.serial_number
        }
      },
      {
        dataIndex: 'details', title: '操作数量',
        render(text) {
          return text.count
        }
      },
      {
        dataIndex: 'details', title: '采样点',
        render(text) {
          return text.location
        }
      },
      {
        dataIndex: 'details', title: '归属账号',
        render(text) {
          return text.nickname
        }
      },
      { dataIndex: 'user', title: '操作人' },
      { dataIndex: 'created_time', title: '操作时间' },
      {
        title: '操作',
        dataIndex: 'details',
        render: (text, record) => <div className='operation-column'>
          <a onClick={() => handleSampleSerialNumber(text.serial_number, record.id, 'download')}>下载</a>
          <a onClick={() => handleSampleSerialNumber(text.serial_number, record.id, 'delete')}>删除</a>
        </div>
      },
    ]
  } else if (whichLog === 'uploadStatistics') {
    currentColumns = [
      { dataIndex: 'created_time', title: '操作时间' },
      {
        dataIndex: 'id',
        title: '操作',
        render: text => <div className='operation-column'>
          <a onClick={() => downloadUploadStatistics(text)}>下载</a>
        </div>
      },
    ]
  }

  return <div className='mainWrapper'>
    {!whichLog && <EvenSearch
      loading={isSearchBtnLoading}
      value={searchTxt}
      onChange={onSearchChange}
    />}

    <Table
      loading={isTableLoading}
      rowKey='id'
      dataSource={table}
      columns={currentColumns}
      pagination={false}
    />
    <EvenPagination current={currentPage}
      total={allCount}
      disabled={isTableLoading}
      onShowSizeChange={onShowSizeChange}
      onPageChange={onPageChange}
    />
  </div>
}
