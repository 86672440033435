import React, { useState } from 'react'
import './index.scss'
import { Menu, } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import { links } from 'router/index'
import store from 'store'

export default function Sidebar(props) {

  // 监听浏览器前进/后退
  useLocation()

  let storeObj = store.getState()
  const [isCollapsed, setCollapsed] = useState(storeObj.isSidebarCollapsed)

  // store.subscribe(() => {
  //   let storeObj = store.getState()
  //   setCollapsed(storeObj.isSidebarCollapsed)
  // })

  const authInfo = JSON.parse(localStorage.getItem('authInfo'))
  const role = authInfo.role
  const history = useHistory()
  const tempLink = links
  const menuItem = tempLink.map(item => {
    if (!item) return undefined
    const { hidden, meta, children, url, title } = item

    if (role !== 1 && title === '用户管理') return false

    const distinguishRole = role !== 1 && role !== 2
    const distinguishTitle = ['上机登记', '上机查询', '上机统计'].includes(title)
    if (distinguishRole && distinguishTitle) {
      return false
    }

    if (hidden) return false
    const icon = meta && meta.icon ? meta.icon : ''
    return {
      children, key: url, label: title, icon,
      onClick: () => history.push(url)
    }
  })

  let defaultOpenKeys = ''
  // if (location.includes('/set/')) {
  //   // defaultOpenKeys = '/set/'
  // }

  return <div className={`sidebarWrapper ${isCollapsed ? 'isSidebarCollapsed' : ''}`}>
    <div className="sideBarMenu">
      <div className="logo">
        <img src={require('../../../assets/images/logo.png')} alt="logo" />
      </div>
      <Menu
        selectedKeys={[window.location.pathname]}
        defaultOpenKeys={[defaultOpenKeys]}
        mode="inline"
        theme="dark"
        // inlineCollapsed={isCollapsed}
        items={menuItem}
      />
    </div>
  </div>
}
