import React, { useEffect, useState } from 'react'
import { Select, Form, Button, Upload, message, DatePicker, Switch, Input } from 'antd'
import { validateMessages, layout, tailLayout } from 'utils/index'
import { downloadInstrumentTemplate, downloadSampleTemplate } from "api/sample"
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons'
import LocationFormItem from './locationFormItem'
import { fetchSystemUsers } from 'api/set/user'
import moment from 'moment'

const { Option } = Select
export default function BulkForm(props) {
  const { importWhich } = props
  const formRef = React.createRef()

  const [search, setSearch] = useState('')
  const [fileList, setFileList] = useState([])
  const [userList, setUserList] = useState([])

  const userOptions = userList.map(i =>
    <Option key={i.id} value={i.id}>{i.nickname}</Option>)

  useEffect(() => {
    if (importWhich === 'instrument') {
      fetchSystemUsers({ search }).then(({ data }) => {
        setUserList(data.results)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const downloadDemo = e => {
    e.stopPropagation()
    let fn = downloadSampleTemplate
    if (importWhich === 'instrument') {
      fn = downloadInstrumentTemplate
    }
    fn().then(response => {
      window.open(response.data.url)
    })
  }

  const onFinish = values => props.submitForm(values)

  const uploadProps = {
    action: '',
    listType: 'picture',
    fileList: [...fileList],
    onRemove: file => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file, fileList) => {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        message.error('文件大小必须小于10MB!')
        return false
      }
      if (fileList.length > 1) {
        message.error('只能上传一个文件!')
        return false
      }
      // setFileList([...fileList, file])
      setFileList([file])
      return false
    },
  }
  const isShowDatePicker = ['result', 'receive', 'instrument'].includes(importWhich)
  let timeLabel = ''
  let timeName = ''
  let timeRule = []
  switch (importWhich) {
    case 'receive':
      timeLabel = '收样时间'
      timeName = 'receive_time'
      timeRule = [{ required: true }]
      break
    case 'instrument':
      timeLabel = '收样时间'
      timeName = 'receive_time'
      timeRule = []
      break
    case 'result':
      timeLabel = '检测时间'
      timeName = 'detection_time'
      timeRule = [{ required: true }]
      break
    default:
  }

  return <>
    {importWhich !== 'result' && <Button
      type="primary"
      shape="round"
      icon={<DownloadOutlined />}
      className={'mB15'}
      onClick={e => downloadDemo(e)}
    >
      下载模板
    </Button>}
    <Form
      {...layout}
      ref={formRef}
      name="basic"
      initialValues={{ is_update: false, is_external: false, is_delivery: false, delivery_remark: '' }}
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        label="导入文件"
        name="file"
        rules={[
          { required: true }
        ]}
      >
        <Upload.Dragger name="file" {...uploadProps} accept=".xlsx, .xls">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">点击上传或将文件拖拽至此处上传</p>
          <p className="ant-upload-hint">支持文件格式：.xlsx, .xls单个文件不能超过10M</p>
        </Upload.Dragger >
      </Form.Item>
      {isShowDatePicker && <Form.Item
        label={timeLabel}
        name={timeName}
        rules={timeRule}
        initialValue={moment(new Date())}
      >
        <DatePicker showTime />
      </Form.Item>}
      {/* 仪器样本 */}
      {importWhich === 'instrument' && <>
        <LocationFormItem name='location_id' required={true} />
        <Form.Item
          label='归属账号'
          name='user_id'
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            filterOption={false}
            onSearch={e => setSearch(e)}
          >
            {userOptions}
          </Select>
        </Form.Item>
        <Form.Item label='是否外送' name='is_delivery'>
          <Switch />
        </Form.Item>
        <Form.Item label='外送备注' name='delivery_remark'>
          <Input />
        </Form.Item>
      </>}
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" className={'mR15'}>确定导入</Button>
      </Form.Item>
    </Form>
  </>
}
