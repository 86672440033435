/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Button, DatePicker, message, Select, Table, Modal, Dropdown, Spin, Switch } from 'antd'
import request from 'utils/request'

import EvenSearch from 'components/search'
import EvenPagination from 'components/pagination'
import EvenModal from 'components/modal'
import { SamplingLocation } from './samplingLocation/index'

import BulkForm from './bulkForm'
import LogTable from 'components/log/index.js'

import {
  fetchSamplesList,
  changeSampleResult,
  importSamples,
  bulkDeleteSamples,
  bulkResetSamples,
  fetchSampleStatuses,
  importResults,
  fetchUpMachineSheet,
  uploadInstrument,
  uploadReceivingSamples,
  fetchSampleLocations,
  recoverSample,
  bulkFreezeSamples,
} from 'api/sample'

import { confirmMessage } from 'utils/index'
import './samplePage.scss'
import { DownOutlined } from '@ant-design/icons'
import { getRole } from 'utils'
import SampleForm from './sampleForm'
import Statistics from './statistics/index'
import moment from 'moment'

const fileDownload = require('js-file-download')

const { Option } = Select
const { RangePicker } = DatePicker
const format = 'YYYY-MM-DD HH:mm:ss'
const inspectionResults = [
  { value: '阴性', text: '阴性' },
  { value: '阳性', text: '阳性' },
  { value: '混阳', text: '混阳' },
]

const today = new Date().toISOString()
const endTime = today.slice(0, 10) + ' 23:59:59'
const before30Days = Date.parse(today) - 7 * 24 * 60 * 60 * 1000
const startTime = new Date(before30Days).toISOString().slice(0, 10) + ' 00:00:00'

export default function SamplePage(props) {

  const [whichLog, setWhichLog] = useState('')

  const [currentTableName] = useState('samples')

  const [isSearchBtnLoading, setIsSearchBtnLoading] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)
  const [isHistoryLoading, setIsHistoryLoading] = useState(false)
  const [isTableLoading, setIsIsTableLoading] = useState(false)
  const [table, setTable] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [allCount, setAllCount] = useState(0)
  const [currentId, setCurrentId] = useState(null)
  const [searchTxt, setSearchTxt] = useState('')

  const [objectId, setObjectId] = useState('')

  const [editable, setEditable] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)

  const [evenModalVisible, setEvenModalVisible] = useState(false)
  const [importWhich, setImportWhich] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [importResultsVisible, setImportResultsVisible] = useState(false)
  const [logVisible, setLogVisible] = useState(false)

  const [modalClosable, setModalClosable] = useState(true)
  const [modalMaskClosable, setModalMaskClosable] = useState(true)
  const [modalMaskShow, setModalMaskShow] = useState(true)
  const [modalStyle] = useState({})
  const [modalZIndex, setModalZIndex] = useState(1000)

  const [sampleStatuses, setSampleStatuses] = useState([])
  const [sampleStatus, setSampleStatus] = useState('20')

  // selected by default
  let samplesSelectedKeys = []
  let samplesSelectedRows = []
  if (props.name === 'sequencingForm' || props.name === 'dynamicField') {
    samplesSelectedKeys = props.selected
  }
  if (props.name === 'sequencingForm') {
    samplesSelectedRows = props.selectedRows
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState(samplesSelectedKeys)
  const [selectedRows, setSelectedRows] = useState(samplesSelectedRows)
  const [minSamplingTime, setMinSamplingTime] = useState(startTime)
  const [maxSamplingTime, setMaxSamplingTime] = useState(endTime)
  const [minReportTime, setMinReportTime] = useState('')
  const [maxReportTime, setMaxReportTime] = useState('')
  const [uploadLoading, setUploadLoading] = useState(false)
  const [sampleTypes, setSampleTypes] = useState([])
  const [selectedTypes, setSelectedTypes] = useState('')
  const [locations, setLocations] = useState([])
  const [selectedLocations, setSelectedLocations] = useState('')
  const [selectedResults, setSelectedResults] = useState('')
  const [is_deleted, setIs_deleted] = useState('false')

  const selectedRowLength = selectedRowKeys.length
  // 不是外部用户
  const notExternalUser = getRole() !== 3

  useEffect(() => {
    fetchSampleStatuses({ keys: 'sample_status' })
      .then(({ data }) => {
        const { sample_status } = data
        const dataArray = []
        for (const key in sample_status) {
          dataArray.push({ value: key, label: sample_status[key] })
        }
        setSampleStatuses(dataArray)
      })

    // 获取采样点
    fetchSampleLocations().then(({ data }) => {
      const { results } = data
      const tempLocations = results.map(i => ({ text: i.location, value: i.id }))
      setLocations(tempLocations)
    })
  }, [])

  useEffect(() => {
    getSampleTypes()
  }, [])

  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, searchTxt, sampleStatus, minSamplingTime, maxSamplingTime, minReportTime, maxReportTime, selectedTypes, selectedLocations, selectedResults, is_deleted])


  const listQuery = () => ({
    page: `${currentPage}`,
    page_size: `${pageSize}`,
    search: `${searchTxt}`,
    status: `${sampleStatus}`,
    min_sampling_time: `${minSamplingTime}`,
    max_sampling_time: `${maxSamplingTime}`,
    min_report_time: `${minReportTime}`,
    max_report_time: `${maxReportTime}`,
    sample_type: selectedTypes,
    location_id: selectedLocations,
    inspection_result: selectedResults,
    is_deleted: is_deleted
  })

  const onTableChange = (pagination, filters) => {
    const { sample_type, location, inspection_result } = filters
    setSelectedLocations(location ? location.join() : '')
    setSelectedTypes(sample_type ? sample_type.join() : '')
    setSelectedResults(inspection_result ? inspection_result.join() : '')
  }

  const handleSwitch = () => {
    if (is_deleted === 'false') {
      setIs_deleted('true')
    } else {
      setIs_deleted('false')
    }
  }

  const handleRangePicker = (e, key) => {
    setCurrentPage(1)
    let min = '', max = ''
    if (e) {
      min = e[0].format(format)
      max = e[1].format(format)
    }
    if (key === 'sample') {
      setMinSamplingTime(min)
      setMaxSamplingTime(max)
      return
    }
    setMinReportTime(min)
    setMaxReportTime(max)
  }

  const getSampleTypes = () => {
    // 获取样本类型
    fetchSampleStatuses({ keys: 'sample_types' })
      .then(({ data }) => {
        const { sample_types } = data
        const tempSampleTypes = []
        for (const iterator of sample_types) {
          tempSampleTypes.push({ text: iterator, value: iterator })
        }
        setSampleTypes(tempSampleTypes)
      })
  }

  const handleSelect = (v) => {
    setCurrentPage(1)
    setSampleStatus(v)
  }

  const getList = async () => {
    setIsIsTableLoading(true)
    fetchSamplesList(listQuery()).then(response => {
      setTable(response.data.results)
      setAllCount(response.data.count)
      setIsIsTableLoading(false)

      if (isSearchBtnLoading)
        setIsSearchBtnLoading(false)
    })
  }

  const onSearchChange = value => {
    setIsSearchBtnLoading(true)
    if (value === searchTxt) {
      setCurrentPage(1)
      getList().then(() => {
        setIsSearchBtnLoading(false)
      })
    } else {
      setCurrentPage(1)
      setSearchTxt(value)
    }
  }
  const onShowSizeChange = (name, current, pageSize) => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }
  const onPageChange = (name, page) => {
    setCurrentPage(page)
  }
  const initModal = () => {
    setModalClosable(true)
    setModalMaskClosable(true)
    setModalMaskShow(true)
    setModalZIndex(1000)
  }


  // 当响应是链接时，避免弹窗被拦截
  const handleDownloadWithLink = async (href1, href2) => {
    const link = document.createElement('a')
    const body = document.querySelector('body')
    link.href = href1
    link.setAttribute('download', '')
    body.appendChild(link)
    link.click()
    if (href2) {
      await new Promise(resolve => {
        setTimeout(() => {
          link.href = href2
          link.click()
          resolve()
        }, 500)
      })
    }
    body.removeChild(link)
    getList()
    closeModal()
  }

  const uploadFile = value => {
    setUploadLoading(true)
    const formData = new FormData()
    const { fileList } = value.file
    if (fileList && fileList.length) {
      fileList.forEach((item) => {
        formData.append('file', item.originFileObj)
      })
    }
    let fn = importSamples
    const { location_id, user_id, detection_time, receive_time, is_delivery, delivery_remark } = value

    switch (importWhich) {
      // 导入检测结果
      case 'result':
        formData.append('detection_time', detection_time.format(format))
        fn = importResults
        break
      // 导入仪器
      case 'instrument':
        formData.append('location_id', location_id)
        formData.append('user_id', user_id)
        formData.append('is_delivery', is_delivery)
        formData.append('delivery_remark', delivery_remark)
        formData.append('receive_time', receive_time.format(format))
        fn = uploadInstrument
        break
      // 收样
      case 'receive':
        formData.append('receive_time', receive_time.format(format))
        fn = uploadReceivingSamples
        break
      default:
    }
    fn(formData).then(({ data }) => {
      message.success('导入成功！')
      if (importWhich === 'instrument') {
        message.success(`操作数量 ${data.count}`)
      }
      getList()
      if (modalTitle === '导入样本') {
        getSampleTypes()
      }
      if (importWhich !== 'result') {
        closeModal()
        return
      }
      const { number, sample } = data.urls
      // 避免弹窗
      handleDownloadWithLink(number, sample)
    }, error => {
      console.log(error)
    }).finally(() => {
      setUploadLoading(false)
    })
  }
  const closeModal = () => {
    setModalVisible(false)
    setEvenModalVisible(false)
    setImportResultsVisible(false)
    setLogVisible(false)
  }
  const submitModal = () => {
    setModalVisible(false)
  }

  const confirmWarning = () => <div className='confirm-warning'>操作数量为{selectedRowLength}</div>

  // 生成报告
  const generateReport = () => {
    const fn = () => {
      request({
        url: 'samples/bulk_report/',
        method: 'POST',
        data: { ids: selectedRowKeys }
      }).then((res) => {
        if (res.status === 200) {
          setSelectedRowKeys([])
          message.success('开始生成报告，请耐心等待')
          getList()
        } else {
          message.error('生成失败！')
        }
      })
    }
    confirmMessage(fn, '确认生成报告？', '取消生成', confirmWarning())
  }

  // 导出
  const exportData = e => {
    setIsExportLoading(true)
    const { key } = e
    let url = 'samples/'
    let responseType = 'blob'
    if (key === 'result') {
      url += 'export2/'
    }
    else if (key === 'report') {
      url += 'export_pdf/'
      responseType = 'json'
    }
    else {
      url += 'export/'
    }
    request({
      url,
      method: 'POST',
      data: { ids: selectedRowKeys },
      responseType
    }).then(res => {
      const { data, headers } = res
      console.log(headers)
      let fileName = '样本'
      if (key === 'result') {
        fileName = '检测结果'
      } else if (key === 'report') {
        handleDownloadWithLink(data.url)
        return
      }
      fileDownload(data, '导出' + fileName + headers.filetype)
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setIsExportLoading(false)
    })
  }

  const exportHistory = e => {
    setIsHistoryLoading(true)
    const { key } = e
    const filename = key === 'free' ? '免费2021.xlsx' : '自费2021.xlsx'
    request({
      url: 'samples/download_files/',
      method: 'POST',
      data: { filename }
    }).then(res => {
      window.open(res.data.url)
    }).finally(() => {
      setIsHistoryLoading(false)
    })
  }

  // 下载上机表
  const downloadUpMachineSheet = (id) => {
    fetchUpMachineSheet(id).then(({ data }) => {
      handleDownloadWithLink(data.url)
    }).catch(err => {
      console.log(err)
    })
  }

  const onTableSelect = (record, selected) => {
    let id = record.id
    let tempKeysArr, tempRowsArr
    if (selected) {
      tempKeysArr = [...selectedRowKeys, id]
      tempRowsArr = [...selectedRows, record]
    } else {
      tempKeysArr = selectedRowKeys.filter(a => a !== id)
      tempRowsArr = selectedRows.filter(a => a.id !== id)
    }
    setSelectedRowKeys(tempKeysArr)
    setSelectedRows(tempRowsArr)

    if (props.name === 'sequencingForm' || props.name === 'dynamicField') {
      props.onSelectChange(tempKeysArr, tempRowsArr)
    }
  }
  const onTableSelectAll = (selected, selectedRowsWithBug, changeRows) => {
    let idArr = changeRows.map(a => a.id)
    let tempKeysArr, tempRowsArr
    if (selected) {
      tempKeysArr = [...selectedRowKeys, ...idArr]
      tempRowsArr = [...selectedRows, ...changeRows]
    } else {
      tempKeysArr = selectedRowKeys.filter(a => !idArr.includes(a))
      tempRowsArr = selectedRows.filter(a => !idArr.includes(a.id))
    }
    setSelectedRowKeys(tempKeysArr)
    setSelectedRows(tempRowsArr)

    if (props.name === 'sequencingForm' || props.name === 'dynamicField') {
      props.onSelectChange(tempKeysArr, tempRowsArr)
    }
  }

  const openSamplingLocation = () => {
    setModalTitle('采样点设置')
    setModalVisible(true)
  }

  const openAddSample = () => {
    initModal()
    setEditable(true)
    setModalTitle('新增样本')
    setModalVisible(true)
  }
  const getDetail = (id, can_modify) => {
    initModal()
    setEditable(can_modify)
    setCurrentId(id)
    setModalTitle('样本详情')
    setModalVisible(true)
  }
  const handleMenuClick = e => {
    const { key } = e
    let title = ''
    setImportWhich(key)
    switch (key) {
      case 'instrument':
        title = '导入仪器样本'
        break
      case 'result':
        title = '导入检测结果'
        break
      case 'sample':
        return openAddSample()
      case 'receive':
        title = '收样'
        break
      default:
        title = '导入样本'
    }
    initModal()
    setModalTitle(title)
    setEvenModalVisible(true)
    setImportResultsVisible(true)
  }
  const openStatistics = () => {
    setModalTitle('数据统计')
    setModalVisible(true)
  }
  const openStatisticsMenu = e => {
    const { key } = e
    switch (key) {
      case 'uploadLog':
        return openLog('仪器样本上传记录')
      case 'uploadStatistics':
        return openLog('标本匹配')
      default: openStatistics()
    }
  }
  const openLog = (title = '操作日志', id) => {
    switch (title) {
      case '操作日志':
        setWhichLog('')
        setObjectId(id)
        break
      case '仪器样本上传记录':
        setWhichLog('uploadLog')
        setObjectId('')
        break
      case '标本匹配':
        setWhichLog('uploadStatistics')
        setObjectId('')
        break
      default:
    }
    setEvenModalVisible(true)
    initModal()
    setLogVisible(true)
    setModalTitle(title)
  }
  const rowClassName = (record) => {
    if (record.is_deleted) {
      return 'highlight-info'
    } else {
      return ''
    }
  }

  const changeResult = (id, isNegative) => {
    const fn = () => {
      changeSampleResult(id, { inspection_result: isNegative ? '阳性' : '阴性' }).then(() => {
        getList()
        message.success('修改成功！')
      })
    }
    confirmMessage(fn, '确定更改此检测结果？', '已取消更改')
  }

  const openReport = (id, record) => {
    request({
      url: `samples/${id}/report/`,
    }).then(({ data }) => {
      window.open(data.url)
    })
  }

  const bulkDelete = () => {
    const fn = () => {
      bulkDeleteSamples({ ids: selectedRowKeys }).then(res => {
        if (res.status === 200) {
          setSelectedRowKeys([])
          getList()
          message.success('删除成功！')
        } else {
          message.error('删除失败！')
        }
      })
    }
    confirmMessage(fn, '确定删除？', '取消删除', confirmWarning())
  }

  const bulkFreeze = () => {
    const fn = () => {
      bulkFreezeSamples({ ids: selectedRowKeys }).then(res => {
        if (res.status === 200) {
          setSelectedRowKeys([])
          getList()
          message.success('冻结成功！')
        } else {
          message.error('冻结失败！')
        }
      })
    }
    confirmMessage(fn, '确定冻结？', '取消冻结', confirmWarning())
  }

  const bulkReset = () => {
    const fn = () =>
      bulkResetSamples({ ids: selectedRowKeys }).then(res => {
        if (res.status === 200) {
          setSelectedRowKeys([])
          getList()
          message.success('重置成功！')
        } else {
          message.error('重置失败！')
        }
      })

    confirmMessage(fn, '确定重置？', '取消重置', confirmWarning())
  }

  const recover = id => {
    const fn = () =>
      recoverSample(id).then(res => {
        if (res.status === 200) {
          getList()
          message.success('恢复成功！')
        } else {
          message.error('恢复失败！')
        }
      })
    confirmMessage(fn, '确定恢复？', '取消')
  }

  const detectingResult = {
    title: '检测结果', dataIndex: 'inspection_result',
    filters: inspectionResults,
    render(text, record) {
      const isNegative = text === '阴性'
      if (!notExternalUser && record.status !== '已完成') {
        return
      }
      return <Button
        type='primary'
        disabled={!record.can_modify}
        style={{
          background: isNegative ? 'green' : 'red',
          border: 'none',
          color: 'white'
        }}
        onClick={() => changeResult(record.id, isNegative)}
      >{text}</Button>
    }
  }
  const normalOperation = (text, record) => (
    <div className='operation-column'>
      <a onClick={() => getDetail(text, record.can_modify)}>详情</a>
      {record.can_view_report &&
        <a onClick={() => openReport(text, record)}>查看报告</a>}
      {notExternalUser && <>
        <a onClick={() => openLog('操作日志', text)}>操作日志</a>
        {record.status === '已完成' && <a onClick={() => downloadUpMachineSheet(text)}>上机表</a>}
      </>}
    </div>
  )
  const deletedOperation = text => (
    <div className='operation-column'>
      <a onClick={() => recover(text)}>恢复</a>
      <a onClick={() => openLog('操作日志', text)}>操作日志</a>
    </div>
  )
  let samplesColumns = [
    { title: '批次编号', dataIndex: 'serial_number', },
    { title: '送检条码号', dataIndex: 'number', },
    { title: '姓名', dataIndex: 'client_name', },
    { title: '证件号码', dataIndex: 'client_id_card', },
    { title: '联系方式', dataIndex: 'client_phone', },
    {
      title: '样本类型', dataIndex: 'sample_type',
      filters: sampleTypes,
      filterSearch: (input, record) => record.value.includes(input),
    },
    { title: '采样时间', dataIndex: 'sampling_time', },
    {
      title: '采样点', dataIndex: 'location',
      filters: locations,
      filterSearch: (input, record) => record.text.includes(input)
    },
    { title: '报告时间', dataIndex: 'report_time', },
    detectingResult,
    {
      title: '操作',
      dataIndex: 'id',
      render: (text, record) => (is_deleted === 'true' ? deletedOperation(text) : normalOperation(text, record))
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    /*preserveSelectedRowKeys: true,*/
    onSelect: onTableSelect,
    onSelectAll: onTableSelectAll,
    getCheckboxProps: record => ({
      disabled: (() => {
        if (props.name === 'sequencingForm') {
          return !record.is_files_exist || record.is_deleted
        } else if (props.name === 'sample') {
          return false
        } else if (props.name === 'dynamicField') {
          return false
        }
      })(), // Column configuration not to be checked
    }),
  }

  const statusOptions = sampleStatuses.map(i =>
    <Option key={i.value} value={i.value}>{i.label}</Option>)

  const importMenu = {
    items: [
      { key: 'result', label: '检测结果' },
      { key: 'instrument', label: '仪器样本' },
      { key: 'sample', label: '新增样本' },
      { key: 'receive', label: '收样' },
    ],
    onClick: handleMenuClick
  }

  const exportMenu = {
    items: [
      { key: 'result', label: '检测结果' },
      { key: 'report', label: '报告' },
    ],
    onClick: exportData
  }

  const historyMenu = {
    items: [
      { key: 'pay', label: '自费' },
      { key: 'free', label: '免费' },
    ],
    onClick: exportHistory
  }

  const statisticsMenu = {
    items: [
      { key: 'uploadLog', label: '上传记录' },
      { key: 'uploadStatistics', label: '标本匹配' },
    ],
    onClick: openStatisticsMenu
  }

  return <div className='mainWrapper sample-page'>
    {props.name !== 'dynamicField' &&
      <EvenSearch
        loading={isSearchBtnLoading}
        value={searchTxt}
        onChange={onSearchChange}
      >
        <div className='search-w'>
          {notExternalUser && <Dropdown.Button
            icon={<DownOutlined />}
            menu={importMenu}
            type='primary'
          >
            导入样本
          </Dropdown.Button>}
          <Dropdown.Button
            icon={<DownOutlined />}
            menu={exportMenu}
            disabled={!selectedRowLength}
            loading={isExportLoading}
            onClick={exportData}
          >
            导出
          </Dropdown.Button>
          <Dropdown menu={historyMenu}>
            <Button loading={isHistoryLoading}>
              导出历史数据
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button
            disabled={!selectedRowLength}
            onClick={() => bulkDelete()}
          >
            批量删除
          </Button>
          {notExternalUser && <>
            <Button
              disabled={!selectedRowLength}
              onClick={() => bulkFreeze()}
            >
              批量冻结
            </Button>
            <Button
              disabled={!selectedRowLength}
              onClick={() => bulkReset()}
            >批量重置</Button>
            <Button
              disabled={!selectedRowLength}
              onClick={() => generateReport()}
            >生成报告</Button>
            <Button onClick={openSamplingLocation}>采样点设置</Button>
            <Dropdown.Button
              icon={<DownOutlined />}
              menu={statisticsMenu}
              onClick={openStatisticsMenu}
            >
              数据统计
            </Dropdown.Button>
            <Switch
              checkedChildren="已删除"
              unCheckedChildren="未删除"
              defaultChecked={false}
              onChange={handleSwitch}
            />
          </>}
          <Select
            className='sample-status'
            mode="multiple"
            defaultValue={sampleStatus}
            onChange={(v) => handleSelect(v)}
          >
            {statusOptions}
          </Select>
        </div>
      </EvenSearch>}
    <div className='range-picker-w'>
      <span>
        <span>采样时间：</span>
        <RangePicker showTime defaultValue={[moment(startTime), moment(endTime)]} onChange={(e) => handleRangePicker(e, 'sample')} />
      </span>
      <span>
        <span>报告时间：</span>
        <RangePicker showTime onChange={(e) => handleRangePicker(e)} />
      </span>
    </div>

    <Table
      loading={isTableLoading}
      rowKey='id'
      dataSource={props.name !== 'dynamicField' ? table : props.data}
      columns={samplesColumns}
      pagination={false}
      rowSelection={rowSelection}
      rowClassName={rowClassName}
      scroll={{ x: 1300 }}
      onChange={onTableChange}
    />
    {props.name !== 'dynamicField' &&
      <EvenPagination current={currentPage}
        total={allCount}
        disabled={isTableLoading}
        onShowSizeChange={onShowSizeChange}
        onPageChange={onPageChange}
        name={currentTableName}>
      </EvenPagination>}


    <Modal
      width='50%'
      title={modalTitle}
      open={modalVisible}
      closable={modalClosable}
      zIndex={modalZIndex}
      maskClosable={modalMaskClosable}
      mask={modalMaskShow}
      style={modalStyle}
      onCancel={closeModal}
      footer={null}
    >
      {modalTitle === '数据统计' && <Statistics />}
      {(modalTitle === '样本详情' || modalTitle === '新增样本') &&
        <SampleForm
          modalTitle={modalTitle}
          currentId={currentId}
          format={format}
          editable={editable}
          notExternalUser={notExternalUser}
          getList={getList}
          getSampleTypes={getSampleTypes}
          setModalVisible={setModalVisible}
        />}
      {modalTitle === '采样点设置' && <div>
        <SamplingLocation />
      </div>}
    </Modal>

    <EvenModal
      title={modalTitle}
      visible={evenModalVisible}
      closable={modalClosable}
      zIndex={modalZIndex}
      maskClosable={modalMaskClosable}
      mask={modalMaskShow}
      style={modalStyle}
      cancel={closeModal}
      submit={submitModal}
    >
      {importResultsVisible &&
        <Spin spinning={uploadLoading}>
          <BulkForm
            importWhich={importWhich}
            cancelForm={closeModal}
            submitForm={value => uploadFile(value)}
          />
        </Spin>}
      {logVisible &&
        <LogTable cancelForm={closeModal} objectId={objectId} whichLog={whichLog} />}
    </EvenModal>
  </div>
}
