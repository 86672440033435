import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Select, Table } from 'antd'
import EvenPagination from 'components/pagination'
import { addGroup, deleteGroup, fetchGroups, updateGroup } from 'api/set/users/group'

import './groupTable.scss'
import { fetchSystemUsers } from 'api/set/user'
import { confirmMessage, handleResponseByStatus } from 'utils'

const { Option } = Select

export default function GroupTable() {
  const [isTableLoading, setIsIsTableLoading] = useState(false)
  const [table, setTable] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [allCount, setAllCount] = useState(0)

  const [visible, setVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [users, setUsers] = useState([])
  const [id, setId] = useState('')

  const [form] = Form.useForm()

  useEffect(() => {
    fetchSystemUsers().then(({ data }) => {
      setUsers(data.results)
    })
  }, [])

  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize])

  const listQuery = () => {
    let tempObj = {
      page: `${currentPage}`,
      page_size: `${pageSize}`,
    }
    return tempObj
  }

  const getList = async () => {
    setIsIsTableLoading(true)
    let tempFunc = (prams) => {
      return fetchGroups(prams)
    }
    await tempFunc(listQuery()).then(({ data }) => {
      setIsIsTableLoading(false)
      setTable(data.results)
      setAllCount(data.count)
    })
  }

  const onShowSizeChange = (name, current, pageSize) => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }
  const onPageChange = (name, page) => {
    setCurrentPage(page)
  }
  const onFinish = values => {
    let fn = () => addGroup(values)
    let msg = '创建'
    if (modalTitle === '编辑用户组') {
      fn = () => updateGroup(id, values)
      msg = '编辑'
    }
    fn().then(res => {
      handleResponseByStatus(res, msg + '成功！', getList)
      closeModal()
    }).catch(err => {
      console.log(err)
    })
  }
  const openModal = (title = '新增用户组') => {
    setModalTitle(title)
    setVisible(true)
  }

  const openEdit = row => {
    openModal('编辑用户组')
    let { name, id, users } = row
    users = users.map(i => i.id)
    setId(id)
    form.setFieldsValue({
      name,
      users
    })
  }

  const closeModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const deleteFn = id => {
    const fn = () => deleteGroup(id).then(res =>
      handleResponseByStatus(res, '删除成功！', getList)
    ).then(err => {
      console.log(err)
    })
    confirmMessage(fn)
  }

  let currentColumns = [
    { dataIndex: 'name', title: '用户组名称' },
    {
      dataIndex: 'users', title: '用户',
      render(data) {
        const text = data.map(i => i.nickname)
        return text.join()
      }
    },
    {
      dataIndex: 'action', title: '操作',
      render(text, row) {
        return <div>
          <Button type="link" onClick={() => openEdit(row)}>编辑</Button>
          <Button type="link" danger onClick={() => deleteFn(row.id)}>删除</Button>
        </div>
      }
    },
  ]

  const userOptions = users.map(i =>
    <Option value={i.id} key={i.id}>{i.nickname}</Option>)

  return <div className='group-table'>
    <Button className='add-btn' type='primary' onClick={() => openModal()}>新增用户组</Button>
    <Table
      loading={isTableLoading}
      rowKey='id'
      dataSource={table}
      columns={currentColumns}
      pagination={false}
    />
    <EvenPagination
      current={currentPage}
      total={allCount}
      disabled={isTableLoading}
      onShowSizeChange={onShowSizeChange}
      onPageChange={onPageChange}
    />
    <Modal
      title={modalTitle}
      open={visible}
      footer={null}
      onCancel={closeModal}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Form.Item
          label="名称"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="用户"
          name="users"
          rules={[{ required: true }]}
        >
          <Select mode='multiple'>
            {userOptions}
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
          <Button type="primary" htmlType="submit">提交</Button>
          <Button style={{ marginLeft: '10px' }} onClick={closeModal}>取消</Button>
        </Form.Item>
      </Form>
    </Modal>
  </div>
}
