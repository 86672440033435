import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Form, Input, message, Select, } from 'antd'
import moment from 'moment'
import request from 'utils/request'

import LocationFormItem from './locationFormItem'
import { fetchSystemUsers } from 'api/set/user'
import { modifySample, addSample, fetchSampleDetail, } from 'api/sample'

import './sampleForm.scss'

const { Option } = Select

export default function (prop) {

  const { modalTitle, currentId, format, getList, setModalVisible, editable, notExternalUser, getSampleTypes } = prop
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')

  const [form] = Form.useForm()

  useEffect(() => {
    fetchSystemUsers({ not_page: true, search }).then(({ data }) =>
      setUsers(data.results)
    )
  }, [search])
  useEffect(() => {
    if (modalTitle === '新增样本') {
      form.resetFields()
      return
    }
    fetchSampleDetail(currentId).then(response => {
      const tempObj = response.data
      const { client_id_card, client_phone, sampling_time, detection_time, receive_time } = tempObj

      tempObj.sampling_time = sampling_time ? moment(sampling_time, format) : ''
      tempObj.receive_time = receive_time ? moment(receive_time, format) : ''
      tempObj.detection_time = detection_time ? moment(detection_time, format) : ''
      if (!notExternalUser) {
        tempObj.client_id_card = client_id_card[0] +
          '***' + client_id_card[client_id_card.length - 1]
      }
      if (!notExternalUser) {
        tempObj.client_phone = client_phone[0] +
          '***' + client_phone[10]
      }
      form.setFieldsValue(tempObj)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalTitle, currentId])

  const submitForm = value => {
    const { sampling_time, receive_time, detection_time } = value
    value.sampling_time = sampling_time ? sampling_time.format(format) : null
    value.receive_time = receive_time ? receive_time.format(format) : null
    value.detection_time = detection_time ? detection_time.format(format) : null
    let fn = () => modifySample(currentId, value)
    let msg = '修改成功！'
    if (modalTitle === '新增样本') {
      fn = () => addSample(value)
      msg = '新增成功！'
    }
    fn().then(() => {
      getList()
      setModalVisible(false)
      message.success(msg)
    }, error => {
      message.error(error.response.data.number[0])
    })
    request({
      url: 'config/',
      method: 'POST',
      data: { key: 'sample_types' }
    }).then(() => {
      getSampleTypes()
    })
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }
  const tailLayout = {
    wrapperCol: { offset: 3 },
  }

  const formItems1 = [
    { label: '送检条码号', name: 'number', isRequired: true, message: '请输入送检条码号' },
    { label: '姓名', name: 'client_name', isRequired: false, message: '请输入姓名' },
    // { label: '证件号码', name: 'client_id_card', isRequired: false, message: '' },
  ]
  const formItems2 = [
    { label: '采样时间', name: 'sampling_time', type: 'time' },
    { label: '收样时间', name: 'receive_time', type: 'time' },
    { label: '检测时间', name: 'detection_time', type: 'time' },
    { label: '样本类型', name: 'sample_type', isRequired: false, message: '请输入样本类型' },
  ]
  const formItems3 = [
    { label: '证件类型', name: 'client_id_type', },
    { label: '送检单位', name: 'inspection_company', isRequired: false, message: '请输入送检单位' },
    { label: '客户单位', name: 'client_company', },
    { label: '国籍', name: 'nationality', },
    { label: '地址', name: 'address', },

    { label: '备注', name: 'remark', },
  ]
  const whichFormItem = type => {
    if (type === 'date') {
      return <DatePicker />
    } else if (type === 'time') {
      return <DatePicker showTime />
    } else {
      return <Input />
    }
  }
  const renderWhichItem = items => items.map(i => <Form.Item
    key={i.name}
    label={i.label}
    name={i.name}
    rules={[{ required: i.isRequired, message: i.message }]}
  >
    {whichFormItem(i.type)}
  </Form.Item>)

  const userOptions = users.map(i =>
    <Option key={i.id} value={i.id}>{i.nickname}</Option>)

  return <Form
    {...layout}
    form={form}
    onFinish={() => submitForm(form.getFieldsValue())}
  >
    <div className='sample-form'>
      {renderWhichItem(formItems1)}
      <Form.Item
        label='证件号码'
        name='client_id_card'
      >
        <Input disabled={!notExternalUser && modalTitle === '样本详情'} />
      </Form.Item>
      <Form.Item
        label='手机号码'
        name='client_phone'
        rules={[{
          required: false,
          message: '请输入手机号码',
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        }]}
      >
        <Input disabled={!notExternalUser && modalTitle === '样本详情'} />
      </Form.Item>
      <Form.Item label='性别' name='gender'>
        <Select>
          <Option value='男'>男</Option>
          <Option value='女'>女</Option>
        </Select>
      </Form.Item>
      <Form.Item label='年龄' name='client_age'>
        <Input />
      </Form.Item>
      {modalTitle === '新增样本' && <Form.Item
        label='归属账号'
        name='creator'
      >
        <Select
          showSearch
          filterOption={false}
          onBlur={() => setSearch()}
          onSearch={e => setSearch(e)}
        >
          {userOptions}
        </Select>
      </Form.Item>}
      {/* 采样点 */}
      <LocationFormItem name='sampling_location' />
      {renderWhichItem(formItems2)}
      <Form.Item
        label='人员类型'
        name='client_type'
      >
        <Select>
          <Option value='确诊患者'>确诊患者</Option>
          <Option value='疑似患者'>疑似患者</Option>
          <Option value='密切接触者'>密切接触者</Option>
          <Option value='表型正常人群'>表型正常人群</Option>
          <Option value='其他'>其他</Option>
        </Select>
      </Form.Item>
      {renderWhichItem(formItems3)}
    </div>
    {editable && <Form.Item {...tailLayout}>
      <Button type='primary' htmlType='submit' >确认</Button>
    </Form.Item>}
  </Form>
}