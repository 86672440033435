import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';

export default class Set extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.history.push('/');
    }, 2000);
  }

  render() {
    return (
      <div className="mainWrapper">
        <div className="wscn-http404-container">
          <div className="wscn-http404">
            <div className="pic-404">
              <img className="pic-404__parent" src={require('../../assets/404_images/404.png')} alt="404" />
              <img className="pic-404__child left" src={require('../../assets/404_images/404_cloud.png')} alt="404" />
              <img className="pic-404__child mid" src={require('../../assets/404_images/404_cloud.png')} alt="404" />
              <img className="pic-404__child right" src={require('../../assets/404_images/404_cloud.png')} alt="404" />
            </div>
            <div className="bullshit">
              <div className="bullshit__oops">OOPS!</div>
              {/* <div class="bullshit__info">版权所有
              <a class="link-type" href="https://wallstreetcn.com" target="_blank">华尔街见闻</a>
            </div> */}
              <div className="bullshit__headline">网管说这个页面你不能进......</div>
              <div className="bullshit__info">请检查您输入的网址是否正确，请点击以下按钮返回主页或者发送错误报告</div>
              <Link to="/" className="bullshit__return-home">返回首页</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
