import React, { useState } from 'react'
import { BackTop } from 'antd'
import HeaderTop from "../Header"
import { Route, Switch } from "react-router-dom"
import { routeComponents } from "../../../router"
import FooterBottom from "../Footer"
import store from 'store'
import { getRole } from 'utils'


export default function RightContent() {
  const role = getRole()
  store.subscribe(() => {
    let storeObj = store.getState()
    setCollapsed(storeObj.isSidebarCollapsed)
  })
  let storeObj = store.getState()
  const [collapsed, setCollapsed] = useState(storeObj.isSidebarCollapsed)

  return (
    <div className={
      `rightContent ${collapsed ? 'isSidebarCollapsed' : ''}`}
    >
      <HeaderTop />
      <Switch>
        {routeComponents.map(item => {
          const { path, component } = item
          if (!item) return undefined
          if (role !== 1 && path === '/users') return undefined
          const distinguishRole = role !== 1 && role !== 2
          const distinguishTitle = ['/checkIn', '/OnBoardInquiry', '/statistics'].includes(path)
          if (distinguishRole && distinguishTitle) {
            return undefined
          }

          return <Route key={path} path={path} component={component} />
        })}
      </Switch>

      <footer className={'footer'}>
        <FooterBottom />
      </footer>
      <BackTop />
    </div>
  )
}
