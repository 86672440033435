/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Dropdown, Input, Menu, message, Modal, Select, Tooltip } from 'antd'
import { addExcelTable, deleteExcelTable, exportExcelTable, fetchExcelTables, fetchBoardOptions, modifyExcelTable, storageExcelTable } from 'api/excelTable'
import React, { useEffect, useState } from 'react'
import EditableExcel from './editableExcel'
import ExcelTable from './excelTable'

import './index.scss'

const { Search } = Input
const { confirm } = Modal
const { Option } = Select

let selectedListItemIndex = 0
if (sessionStorage.getItem('selectedListItemIndex')) {
  selectedListItemIndex = sessionStorage.getItem('selectedListItemIndex')
}

export default function () {

  const [listData, setListData] = useState([])
  const [search, setSearch] = useState('')
  const [filename, setFilename] = useState('')
  const [isOpenSheetName, setIsOpenSheetName] = useState(false)
  // 选中的左侧总表id
  const [currentId, setCurrentId] = useState(undefined)
  const [isOpenEditableExcel, setIsOpenEditableExcel] = useState(false)
  const [boardOptions, setBoardOptions] = useState([])
  const [selectedBoard, setSelectedBoard] = useState(null)
  const [isAddSheet, setIsAddSheet] = useState(true)

  const onSearch = value => setSearch(value)

  // 初始化列表项选中样式
  const setListItemClass = results => {
    for (const iterator of results)
      iterator.className = 'list-item'
    results[selectedListItemIndex].className = 'list-item selected'
  }

  const getBoardOptions = () => {
    fetchBoardOptions(currentId).then(({ data }) => {
      const { serial_numbers } = data
      setBoardOptions(serial_numbers)
      if (serial_numbers.length) {
        const storageBoard = sessionStorage.getItem('selectedBoard')
        if (storageBoard) {
          setSelectedBoard(storageBoard)
        } else {
          setSelectedBoard(serial_numbers[0])
        }
        sessionStorage.setItem('selectedBoard', serial_numbers[0])
      }
    })
  }

  const getExcelTables = () => {
    fetchExcelTables({ search }).then(({ data }) => {
      const { results } = data
      setListItemClass(results)
      setListData(results)

      const storageId = sessionStorage.getItem('checkInSheetId')
      if (storageId) {
        setCurrentId(storageId)
        return
      }
      const { id } = results[0]
      setCurrentId(id)
      sessionStorage.setItem('checkInSheetId', id)
    })
  }

  const addSheet = () => {
    setIsAddSheet(true)
    setFilename('')
    setIsOpenSheetName(true)
  }

  const submitSheetName = () => {
    let fn = () => addExcelTable(filename)
    if (!isAddSheet) {
      fn = () => modifyExcelTable(filename, currentId)
    }
    fn().then(res => {
      setSelectedBoard(null)
      sessionStorage.removeItem('selectedBoard')
      getExcelTables()
      setIsOpenSheetName(false)
    })
  }

  const handleDelete = id => {
    confirm({
      title: '确定删除此表？',
      onOk() {
        deleteExcelTable(id).then((res) => {
          console.log(res)
          window.location.reload()
        })
      },
    })
  }

  const handleDropdown = (k, id, filename) => {
    setCurrentId(id)
    switch (k) {
      case 'edit':
        setIsAddSheet(false)
        setFilename(filename)
        setIsOpenSheetName(true)
        break
      case 'delete':
        handleDelete(id)
        break
      case 'export':
        exportExcelTable(id).then(({ data }) =>
          window.open(data.url)
        )
        break
      default:
    }
  }

  const closeEditableModal = () => {
    if (sessionStorage.getItem('unSubmitted') === 'false') {
      setIsOpenEditableExcel(false)
      return
    }
    confirm({
      title: '存在数据未提交，确认离开？',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setIsOpenEditableExcel(false)
        sessionStorage.setItem('unSubmitted', false)
      },
    })
  }

  const clickListItem = index => {
    if (index === selectedListItemIndex)
      return
    const listItem = JSON.parse(JSON.stringify(listData))
    selectedListItemIndex = index
    sessionStorage.setItem('selectedListItemIndex', index)

    const { id } = listItem[index]
    setCurrentId(id)
    sessionStorage.setItem('checkInSheetId', id)

    setListItemClass(listItem)
    setListData(listItem)
    setSelectedBoard(null) // 避免没有板号的总表去获取表格数据
    sessionStorage.removeItem('selectedBoard')
  }

  const selectBoard = v => {
    setSelectedBoard(v)
    sessionStorage.setItem('selectedBoard', v)
  }

  const openSheetModal = isAdd => {
    setIsAddSheet(isAdd)
    setIsOpenEditableExcel(true)
  }

  const handleStorage = () => {
    confirm({
      title: '入库后将无法修改，是否确定入库？',
      onOk() {
        storageExcelTable(currentId).then(res => {
          if (res.status === 200) {
            message.success('入库成功！')
            getExcelTables()
            return
          }
          message.error('入库失败！')
        })
      },
    })
  }

  useEffect(() => {
    getExcelTables()
  }, [search])
  useEffect(() => {
    if (currentId)
      getBoardOptions()
  }, [currentId])

  const menu = (id, filename) => <Menu
    onClick={({ key }) => handleDropdown(key, id, filename)}
    items={[
      { key: 'edit', label: '编辑' },
      { key: 'delete', label: '删除' },
      { key: 'export', label: '导出' },
    ]}
  />

  const listItem = (filename, created_time, id, index, className) =>
    <div className={className} key={id} onClick={() => clickListItem(index)}>
      <div>
        <div>{filename}</div>
        <div className='sheet-date'>{created_time}</div>
      </div>
      <Dropdown overlay={menu(id, filename)} placement="bottomLeft" trigger={['click']}>
        <MoreOutlined />
      </Dropdown>
    </div>

  return <div className='check-in'>
    <div className='left-side'>
      <Search
        allowClear
        placeholder="请输入搜索内容"
        onSearch={onSearch}
      />
      <Tooltip title='新增总表' placement='left'>
        <Button
          className='add-btn'
          type="primary"
          shape="circle"
          size='small'
          icon={<PlusOutlined />}
          onClick={() => addSheet()}
        />
      </Tooltip>
      <div className='sheet-list'>
        {listData.map((i, index) =>
          listItem(i.filename, i.created_time, i.id, index, i.className))}
      </div>
    </div>
    <div className='right-side'>
      <div className='operation-w'>
        <div className='btn-w'>
          <Button type='primary' onClick={() => openSheetModal(true)}>新增</Button>
          <Button
            disabled={!boardOptions.length}
            onClick={() => openSheetModal()}
          >
            编辑
          </Button>
          <Button onClick={handleStorage}>入库</Button>
          <Select value={selectedBoard} onChange={v => selectBoard(v)}>
            {boardOptions.map(i => <Option value={i} key={i}>第{i}板</Option>)}
          </Select>
        </div>
      </div>
      <div className='legend-w'>
        <div className='storage-fail'>入库失败</div>
        <div className='code-repeat'>条码重复</div>
        <div className='double-code'>双条码</div>
        <div className='env-sample'>环境样本</div>
      </div>
      <ExcelTable
        sheetId={currentId}
        boardId={selectedBoard}
        isModalOpen={isOpenEditableExcel}
      />
    </div>
    <Modal
      width='100%'
      footer={null}
      destroyOnClose
      maskClosable={false}
      open={isOpenEditableExcel}
      onCancel={closeEditableModal}
    >
      <EditableExcel
        sheetId={currentId}
        boardId={selectedBoard}
        boardIds={boardOptions}
        isAddSheet={isAddSheet}
        closeModal={setIsOpenEditableExcel}
        getBoardOptions={getBoardOptions}
      />
    </Modal>
    <Modal
      title={isAddSheet ? '新增表名' : '修改表名'}
      open={isOpenSheetName}
      onOk={submitSheetName}
      onCancel={() => setIsOpenSheetName(false)}
    >
      <Input
        placeholder='请输入新表名'
        value={filename}
        onChange={e => setFilename(e.target.value)}
      />
    </Modal>
  </div >
}
