// CEll_STATUS = {10: '未入库', 20: '入库失败', 30: '已入库', 40: '上传中', 50: '已上传'}


/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, message, Modal, Select } from "antd"
import { addCells, addSerialNum, deleteBoard, fetchExcelTableData, updateBoardId } from "api/excelTable"
import { fetchUsers } from "api/set/user"
import React, { useEffect, useState } from "react"
import { getAuthInfo } from "utils"
import FormItem from "./formItem"

import './index.scss'

const { confirm } = Modal

let submitData = {
  serial_number: null,
  cells: [
    // { "id": 0, "row": 2, "column": 3, "number": "11", "s_type": 1, "labels": "", "account_id": 1 },
  ]
}

const { Option } = Select

let tableTemp = []
for (let i = 0; i < 8; i++) {
  tableTemp[i] = []
  for (let j = 0; j < 12; j++) {
    tableTemp[i][j] = { number: '' }
  }
}

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',]

const userId = getAuthInfo().id

let timeout = null

const coordinates = [] // 用于保存多选的表格项

export default function (props) {
  // sheetId: 总表名
  const { getBoardOptions, sheetId, isAddSheet, boardId, boardIds } = props
  const [users, setUsers] = useState([])
  const [selectUserId, setSelectUserId] = useState(null)
  const [selectSampleType, setSelectSampleType] = useState(1)
  const [tableNum, setTableNum] = useState(null)
  const [tableData, setTableData] = useState(JSON.parse(JSON.stringify(tableTemp)))
  // 当前坐标
  const [coordinate, setCoordinate] = useState({})

  // 数量统计
  const [statistics, setStatistics] = useState([{ account_id: '' }])

  const handleSubmit = () => {
    if (!tableNum) {
      message.error('请先填写板号！')
      return
    }
    if (!selectUserId) {
      message.error('请先选择账号！')
      return
    }
    submitData.serial_number = parseInt(tableNum)
    const cellsTemp = [] // 新增的表格项
    // 更新
    if (!isAddSheet) {
      // 提取要提交的数据
      for (const iterator of tableData) {
        for (const item of iterator) {
          const { s_type, number, id, isUpdated, account_id } = item
          if (!account_id) {
            item.account_id = selectUserId
          }
          if (!s_type) {
            item.s_type = 1
          }
          if ((number || id) && isUpdated)
            cellsTemp.push(item)
        }
      }
    } else {
      // 新增
      // 提取要提交的数据
      for (const iterator of tableData) {
        for (const item of iterator) {
          const { number, isSubmitted, account_id } = item
          if (!account_id) {
            item.account_id = selectUserId
          }
          if (number && !isSubmitted)
            cellsTemp.push(item)
        }
      }
    }
    submitData.cells = cellsTemp
    addCells(submitData, sheetId)
      .then(res => {
        if (res.status !== 200) return
        getBoardOptions()

        // if (!isAddSheet)
        getExcelTableData()

        for (const iterator of tableData) {
          for (const item of iterator) {
            if (item.number)
              // 标记已提交的
              item.isSubmitted = true
          }
        }
        sessionStorage.setItem('unSubmitted', false)
        setTableData(tableData)

        message.success('提交成功！')
      })
  }

  const handleFormItemBlur = e => {
    const { row, column } = e

    const arrRow = row - 1
    const arrColumn = column - 1

    // 获取输入值
    const value = form.getFieldValue(arrRow + ',' + arrColumn)

    const currentItem = tableData[arrRow][arrColumn]
    const { number } = currentItem
    // 两者之一为真且不相等，意味着此表单项被修改过，予以标记
    if ((number || value) && (number !== value) && !isAddSheet) {
      tableData[arrRow][arrColumn].isUpdated = true
    }
    // tableData[arrRow][arrColumn].number = value

    // 查看是否存在未提交的数据
    let unSubmitted = false
    for (const iterator of tableData) {
      for (const item of iterator) {
        if (!item.isSubmitted && (item.number || value)) {
          unSubmitted = true
          break
        }
      }
    }
    sessionStorage.setItem('unSubmitted', unSubmitted)
    if (!value && isAddSheet) return

    if (!currentItem.s_type) {
      tableData[arrRow][arrColumn].s_type = 1
    }
    if (!currentItem.account_id) {
      tableData[arrRow][arrColumn].account_id = selectUserId
    }
    tableData[arrRow][arrColumn].id = currentItem.id || 0
    tableData[arrRow][arrColumn].row = row
    tableData[arrRow][arrColumn].column = column
    console.log(value)
    tableData[arrRow][arrColumn].number = value
    setTableData(JSON.parse(JSON.stringify(tableData)))
  }

  const handleFormItemFocus = e => {
    const { column, row, value } = e
    const arrRow = row - 1
    const arrColumn = column - 1
    const currentItem = tableData[arrRow][arrColumn]
    const { number, account_id, s_type } = currentItem
    if (number && !account_id) {
      message.warning('请选择账号！')
    }
    // 定位坐标
    setCoordinate({ row, column })
    // 显示选中项的账号和样本类型
    if (!value && !number) {
      setSelectSampleType(1)
    } else {
      setSelectSampleType(s_type)
      setSelectUserId(account_id)
    }
    setTableData(JSON.parse(JSON.stringify(tableData)))
  }

  const [form] = Form.useForm()
  const nextBoard = () => {
    form.resetFields()
    let tempNum = tableNum + 1
    while (boardIds.includes(tempNum)) {
      tempNum++
    }
    setTableNum(tempNum)
    addBoardId(tempNum, sheetId)
    setSelectUserId(null)
    setTableData(JSON.parse(JSON.stringify(tableTemp)))
  }

  const handleSelect = (v, name) => {
    const { row, column } = coordinate
    let key = ''
    if (name === 'user') {
      setSelectUserId(v)
      key = 'account_id'
    } else {
      setSelectSampleType(v)
      key = 's_type'
    }
    if (row) {
      tableData[row - 1][column - 1][key] = v
    }
    for (const iterator of tableData) {
      for (const item of iterator) {
        if (item.isChecked) {
          console.log(item)
          item[key] = v
        }
      }
    }
    setTableData(JSON.parse(JSON.stringify(tableData)))
  }

  const handleSearchSelect = (v) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    const fn = () => {
      getUsers(v)
    }
    timeout = setTimeout(fn, 500)
  }

  const getExcelTableData = () =>
    fetchExcelTableData(sheetId, tableNum || boardId).then(({ data }) => {
      const { cells, statistics } = data

      setStatistics(statistics)

      const formObj = {}
      const temp = JSON.parse(JSON.stringify(tableTemp))

      for (const iterator of cells) {
        const curRow = iterator.row - 1
        const curColumn = iterator.column - 1
        formObj[curRow + ',' + curColumn] = iterator.number
        temp[curRow][curColumn] = iterator

        temp[curRow][curColumn].className = temp[curRow][curColumn].labels
        if (iterator.double) {
          temp[curRow][curColumn].className = 'code-repeat'
        }
      }
      form.setFieldsValue(formObj)
      setTableData(JSON.parse(JSON.stringify(temp)))
    })

  const addBoardId = (num) => {
    if (!num) {
      message.warning('请填写板号！')
      return
    }

    addSerialNum(num, sheetId).then(res =>
      console.log(res)
    ).catch(err =>
      console.log(err)
    )
  }

  const submitBoardId = () => {
    if (isAddSheet && !boardIds.includes(sheetId)) {
      addBoardId(tableNum)
      return
    }
    updateBoardId(boardId, tableNum, sheetId).then(res => {
      if (res.status !== 200) return
      message.success('提交成功！')
      getBoardOptions()
    })
  }

  const handleDeleteBoard = () =>
    confirm({
      title: '确定删除？',
      onOk() {
        deleteBoard(sheetId, parseInt(boardId)).then(res => {
          if (res.status === 200)
            window.location.reload()
        })
      },
    })

  // 变成双条码
  const turnIntoDouble = () => {
    const { row, column } = coordinate
    if (!row || !column) {
      message.error('请先选择表格项！')
      return
    }
    const tableTemp = JSON.parse(JSON.stringify(tableData))
    tableTemp[row - 1][column - 1].className = 'double'
    tableTemp[row - 1][column - 1].labels = 'double'
    tableTemp[row - 1][column - 1].isUpdated = true
    setTableData(JSON.parse(JSON.stringify(tableTemp)))
  }

  // 点击图例
  const clickEnv = () => {
    const { row, column } = coordinate
    if (!row || !column) {
      message.error('请先选择表格项！')
      return
    }
    const tableTemp = JSON.parse(JSON.stringify(tableData))
    tableTemp[row - 1][column - 1].className = 'environment'
    tableTemp[row - 1][column - 1].labels = 'environment'
    tableTemp[row - 1][column - 1].isUpdated = true
    setTableData(JSON.parse(JSON.stringify(tableTemp)))
  }

  const getUsers = search =>
    fetchUsers(userId, { search }).then(({ data }) =>
      setUsers(data.results)
    )

  const handleCheck = v => {
    const { row, column } = v
    const tableTemp = JSON.parse(JSON.stringify(tableData))
    const currentRow = row - 1
    const currentColumn = column - 1
    const currentItem = tableTemp[currentRow][currentColumn]

    if (!currentItem.isChecked) {
      currentItem.checkClass = 'checked'
      currentItem.isChecked = true
      coordinates.push({ row: currentRow, column: currentColumn })
    } else {
      currentItem.checkClass = ''
      currentItem.isChecked = false
      for (let index = 0; index < coordinates.length; index++) {
        const { row, column } = coordinates[index]
        if (row === currentRow && column === currentColumn) {
          coordinates.splice(index, 1)
          break
        }
      }
    }
    setTableData(JSON.parse(JSON.stringify(tableTemp)))
  }

  useEffect(() => {
    getUsers()

    // 编辑情况
    if (isAddSheet) return

    setTableNum(boardId)
    getExcelTableData()
  }, [])

  const formItems = (row, item) => {
    const arr = []
    for (let index = 0; index < 12; index++) {
      const { isSubmitted, className, labels, double, status, number, checkClass } = item[index]

      item[index].className = labels
      // 入库失败
      if (status === 20) {
        item[index].className = 'storage-fail'
      }
      if (double) {
        item[index].className = 'code-repeat'
      }

      let cellHtml = <div
        key={index}
        className={item[index].className}
      // onClick={() => !selectUserId && message.warning('请先选择账号！')}
      >
        <FormItem
          row={row}
          column={index}
          isInputDisabled={isSubmitted && isAddSheet}
          checkClass={checkClass}
          onCheck={handleCheck}
          onFocus={v => handleFormItemFocus(v)}
          onBlur={v => handleFormItemBlur(v)}
        />
      </div>

      // 只有未入库和入库失败的才可编辑
      if (status && ![10, 20].includes(status)) {
        cellHtml = <div
          key={index}
          className={className}
        >
          {number}
        </div>
      }

      arr.push(cellHtml)
    }
    return arr
  }

  const renderTb = (letter, index, item) =>
    <div className='tb' key={letter}>
      <div className='row-h'>{letter}</div>
      {formItems(index, item)}
    </div>

  const renderOpt = users.map(i =>
    <Option key={i.id} value={i.id}>{i.nickname}</Option>)

  const firstBtn = isAddSheet ? <Button onClick={nextBoard}>创建下一板</Button> :
    <Button danger type="primary" onClick={handleDeleteBoard}>删除该板</Button>

  return <div className='editable-excel'>
    <div className='left-part'>
      <div className='title'>
        第
        <Input
          type="number"
          value={tableNum}
          onChange={e => setTableNum(parseInt(e.target.value))}
          onBlur={submitBoardId}
        />
        板
      </div>
      <div className='table'>
        <div className='th'>
          <div className='row-h'></div>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
          <div>9</div>
          <div>10</div>
          <div>11</div>
          <div>12</div>
        </div>
        <Form form={form}>
          {letters.map((item, index) => renderTb(item, index, tableData[index]))}
        </Form>
      </div>
      <div className='btn-group'>
        {firstBtn}
        <Button type='primary' onClick={handleSubmit}>提交</Button>
      </div>
    </div>

    <div className='right-part'>
      <div>账号：</div>
      <Select
        showSearch
        allowClear
        filterOption={false}
        value={selectUserId}
        onSearch={handleSearchSelect}
        onChange={v => handleSelect(v, 'user')}
        onClear={getUsers}
      >
        {renderOpt}
      </Select>
      <div>样本类型：</div>
      <Select value={selectSampleType} onChange={v => handleSelect(v)}>
        <Option value={1}>单管</Option>
        <Option value={10}>10混</Option>
        <Option value={20}>20混</Option>
      </Select>
      <div className='legend-title'>标注：</div>
      <div className='code-repeat legend'>条码重复</div>
      <div className='double-code legend' onClick={turnIntoDouble}>双条码</div>
      <div className='env-sample legend' onClick={clickEnv}>环境样本</div>

      <div className='statistics'>数量统计：</div>

      {statistics.map(item => <div key={item.account_id}>
        <div className='unit'>{item.nickname}</div>
        <div className='count'>已录入{item.total}管</div>
      </div>
      )}
    </div>
  </div>
}