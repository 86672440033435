export const clinicalPathogensArr = ['细菌', '真菌', '病毒', '寄生虫', '结合分枝杆菌', '支原体/衣原体', '其它']
export const nucleicAcidsList = ['DNA', 'RNA']
export const chunkSize = 10485760 // 10MB
export const fractionDigits = 2
export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
export const initUploadObj = {
  name: 'samples', // 只有两个值 [’samples‘, 'analysis_modules + id']
  title: '上传外部样本文件', //需要自定义
  //accept: '.fq.gz', //需要自定义
  limit: 10737418240, // 目前为10 GB， 可自定义
  file_type: '' // 有四个值 [’‘, ‘pipeline’, ‘database’, ‘image’]
}

export const patientAgeUnitList = ['岁', '月', '周', '天']
export const lymphocyteUnitList = ['*10^9/L', '*10^12/L', '%']
export const neutrophilUnitList = ['*10^9/L', '*10^12/L', '%', 'mg/L']
export const wbcUnitList = ['*10^9/L', '*10^12/L', '%']
export const crpUnitList = ['mg/L', 'g/L', 'ng/L']
export const pctUnitList = ['*10^9/L', 'ng/ml']

export const breakPoint = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
}
export const requestTimeout = 1000000000000000
