import request from 'utils/request'

export function fetchCellList(created_time) {
  return request({
    url: 'statistics/cell_list/',
    method: 'GET',
    params: { created_time }
  })
}

export function fetchCellChartsData(min_created_time, max_created_time) {
  return request({
    url: 'statistics/cell_table/',
    method: 'GET',
    params: { min_created_time, max_created_time }
  })
}