/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Table, Input, Button, Form, Typography, Popconfirm } from 'antd'

import { addSampleLocation, deleteSampleLocations, editSampleLocation, fetchSampleLocations } from 'api/sample'
import './index.scss'

const EditableCell = ({
  editing,
  dataIndex,
  title,
  children,
}) => {
  return (
    <td>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `请输入${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const SamplingLocation = () => {
  const [form] = Form.useForm()
  const [tableData, setTableData] = useState([])
  const [editingKey, setEditingKey] = useState('')
  const [isAdding, setIsAdding] = useState(false)
  const [deletedId, setDeletedId] = useState('')

  useEffect(() => {
    getList()
  }, [])

  const getList = () => {
    fetchSampleLocations().then(({ data }) => {
      const { results } = data
      for (const iterator of results)
        iterator.key = iterator.location
      setTableData(data.results)
    })
  }

  const isEditing = record => record.key === editingKey

  const add = () => {
    setIsAdding(true)
    form.resetFields()
    const newData = [...tableData]
    newData.unshift({
      dataIndex: 'add',
      key: "add",
      location: ""
    })
    setTableData(newData)
    setEditingKey('add')
  }

  const edit = record => {
    form.setFieldsValue({
      ...record,
    })
    setEditingKey(record.key)
  }

  const deleteData = record => {
    console.log(record)
    setDeletedId(record.id)
  }

  const cancel = () => {
    if (editingKey === 'add') {
      const newData = [...tableData]
      newData.shift()
      setTableData(newData)
    }
    setEditingKey('')
    setDeletedId('')
    setIsAdding(false)
  }

  const submit = async () => {
    try {
      await form.validateFields()
      const { id, location } = form.getFieldValue()
      const data = { location }
      let fn = () => addSampleLocation(data)
      if (editingKey !== 'add' && !deletedId) {
        fn = () => editSampleLocation(data, id)
      } else if (deletedId) {
        fn = () => deleteSampleLocations(deletedId)
      }
      fn().then(res => {
        if (res.status === 200)
          getList()
      }).catch(err => {
        console.log(err)
      })
      setEditingKey('')
      setDeletedId('')
      setIsAdding(false)
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const columns = [
    {
      title: '采样点名称',
      dataIndex: 'location',
      editable: true,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_, record) => {
        const disabled = (editingKey !== '')
        const editable = isEditing(record)
        return editable ? <span>
          <a
            className='left-btn'
            onClick={() => submit(record.key)}
          >
            确定
          </a>
          <a onClick={cancel}>取消</a>
        </span> :
          <span>
            <Typography.Link
              className='left-btn'
              disabled={disabled}
              onClick={() => edit(record)}
            >
              编辑
            </Typography.Link>
            <Popconfirm title='确定要删除吗？' onConfirm={submit}>
              <Typography.Link
                disabled={disabled}
                onClick={() => deleteData(record)}
              >
                删除
              </Typography.Link>
            </Popconfirm>
          </span>
      },
    },
  ]
  const mergedColumns = columns.map((col) => {
    if (!col.editable) return col

    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })
  return <div className='sampling-location'>
    <Form form={form} component={false}>
      <Button
        type='primary'
        className='add-btn'
        disabled={isAdding}
        onClick={add}
      >
        新增采样点
      </Button>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={tableData}
        columns={mergedColumns}
      />
    </Form>
  </div>
}

export { SamplingLocation }
