import React, { useEffect } from 'react'
import { Pagination } from 'antd'

export default function AtdPagination(props) {
  useEffect(() => {

  }, [])

  return <div className='flexWrap spaceBetween paginationWrapper'>
    <div>
      {props.slot || props.children}
    </div>
    <Pagination defaultCurrent={1}
      current={props.current}
      total={props.total}
      showTotal={total => `共 ${total} 条`}
      showQuickJumper
      showSizeChanger
      disabled={props.disabled}
      pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
      onShowSizeChange={(current, pageSize) => props.onShowSizeChange(props.name, current, pageSize)}
      onChange={(page, pageSize) => props.onPageChange(props.name, page, pageSize)} />
  </div>
}
