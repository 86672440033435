import request from 'utils/request'

const locationUrl = 'sample_locations/'

export function downloadOrDeleteSample(data) {
  return request({
    url: 'samples/handle_serial_number/',
    method: 'POST',
    data
  })
}

export function fetchStatisticsSample(params) {
  return request({
    url: 'statistics/samples/',
    method: 'GET',
    params
  })
}

export function postStatisticsSample(data) {
  return request({
    url: 'statistics/samples/',
    method: 'POST',
    data
  })
}

export function fetchUploadLog(params) {
  return request({
    url: 'logs/instrument/',
    method: 'GET',
    params
  })
}

export function uploadReceivingSamples(data) {
  return request({
    url: 'samples/update_by_upload_result/',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export function fetchSampleLocations(params) {
  return request({
    url: locationUrl + '?not_page=true',
    method: 'GET',
    params
  })
}
export function deleteSampleLocations(id) {
  return request({
    url: locationUrl + id + '/',
    method: 'DELETE',
  })
}
export function addSampleLocation(data) {
  return request({
    url: locationUrl,
    method: 'POST',
    data
  })
}
export function editSampleLocation(data, id) {
  return request({
    url: locationUrl + id + '/',
    method: 'PUT',
    data
  })
}



export function postUploadStatistics(id) {
  return request({
    url: 'samples/handle_upload_result_log/',
    method: 'POST',
    data: {
      log_id: id,
      action: 'download'
    }
  })
}

export function fetchSamplesList(query) {
  return request({
    url: 'samples/',
    method: 'GET',
    params: query
  })
}
export function fetchSampleDetail(id) {
  return request({
    url: `samples/${id}/`,
    method: 'GET'
  })
}
export function addSample(data) {
  return request({
    url: `samples/`,
    method: 'POST',
    data
  })
}
export function modifySample(id, data) {
  return request({
    url: `samples/${id}/`,
    method: 'PUT',
    data
  })
}
export function changeSampleResult(id, data) {
  return request({
    url: `samples/${id}/`,
    method: 'PATCH',
    data
  })
}
export function deleteSample(id) {
  return request({
    url: `samples/${id}/`,
    method: 'delete'
  })
}

// 下载样本模板
export function downloadSampleTemplate() {
  return request({
    url: `samples/upload/`,
    method: 'GET',
  })
}
// 导入样本
export function importSamples(data) {
  return request({
    url: `samples/upload/`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
// 导入检测结果
export function importResults(data) {
  return request({
    url: `samples/upload_result/`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export function fetchUpMachineSheet(id) {
  return request({
    url: `samples/${id}/table/`,
    method: 'GET'
  })
}



export function downloadInstrumentTemplate() {
  return request({
    url: `samples/upload_by_instrument/`,
    method: 'GET',
  })
}
// 导入仪器样本
export function uploadInstrument(data) {
  return request({
    url: `samples/upload_by_instrument/`,
    method: 'POST',
    data
  })
}

export function bulkDeleteSamples(data) {
  return request({
    url: `samples/bulk_delete/`,
    method: 'POST',
    data
  })
}

export function bulkFreezeSamples(data) {
  return request({
    url: `samples/bulk_freeze/`,
    method: 'POST',
    data
  })
}

export function bulkResetSamples(data) {
  return request({
    url: `samples/bulk_reset/`,
    method: 'POST',
    data
  })
}

export function recoverSample(id) {
  return request({
    url: `samples/${id}/recover/`,
    method: 'POST'
  })
}

export function fetchDepartmentsList(query) {
  return request({
    url: 'submitted_departments/',
    method: 'GET',
    params: query
  })
}


export function fetchSamplesSavedValuesList(query) {
  return request({
    url: 'samples/saved_values/',
    method: 'GET',
    params: query
  })
}



export function fetchSampleStatuses(params) {
  return request({
    url: 'config/',
    method: 'GET',
    params
  })
}