/**
 * Created by jiachenpan on 16/11/18.
 */

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/* 合法uri*/
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/* 小写字母*/
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母*/
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/* 大小写字母*/
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * validate email
 * @param email
 * @returns {boolean}
 */
export function validEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}
export function validMultipleEmail(email) {
  // eslint-disable-next-line
  const re = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*(;|,)\s*|\s*$))*$/
  return re.test(email)
}

export function validPhone(phone) {
  const re = /^1[34578]\d{9}$/
  return re.test(phone)
}

export function validTel(tel) {
  const re = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/
  return re.test(tel)
}
// yyyy-mm-dd
export function validDate(date) {
  // eslint-disable-next-line
  const re = /^\d{4}[\-](0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])$/
  return re.test(date)
}
// yyyy-mm-dd hh:mm:ss
export function validDateTime(date) {
  // eslint-disable-next-line
  const re = /^\d{4}[\-](0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])[\s+](?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/
  return re.test(date)
}

// 身份证
export function validIdNum(id) {
  const re = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return re.test(id)
}

// regular expression for letters, numbers and - _
export function validLetterNumber(val) {
  const re = /^[a-zA-Z0-9_-]*$/
  return re.test(val)
}

export function validSame(value1, value2) {
  return (value1 === value2)
}
export function validPositiveZeroNum(value) {
  return !isNaN(value) && value >= 0
}
export function validPositiveNum(value) {
  return !isNaN(value) && value > 0
}
export function validPositiveZeroNumLess100(value) {
  return !isNaN(value) && value >= 0 && value <= 100
}
export function validPositiveInteger(val) {
  const re = /^[1-9][0-9]*$/
  return re.test(val)
}
export function validPositiveIntegerAndZero(val) {
  const re = /(^([0])$)|(^[0-9]*[1-9][0-9]*$)/
  return re.test(val)
}
