import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

import { fetchSampleLocations } from 'api/sample'

const { Option } = Select
export default function (props) {
  const { required, name } = props
  const [search, setSearch] = useState('')
  const [locations, setLocations] = useState([])
  const locationOptions = locations.map(i =>
    <Option key={i.id} value={i.id}>{i.location}</Option>)

  useEffect(() => {
    getLocations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const getLocations = () => {
    fetchSampleLocations({ search }).then(({ data }) => {
      const { results } = data
      setLocations(results)
    })
  }

  const rules = [{ required: required }]

  return <Form.Item
    label='采样点'
    name={name}
    rules={rules}
  >
    <Select
      showSearch
      filterOption={false}
      onBlur={() => setSearch()}
      onSearch={e => setSearch(e)}
    >
      {locationOptions}
    </Select>
  </Form.Item>
}