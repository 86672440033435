import React, { useState } from "react"
import { Input, Form, Checkbox } from "antd"

import './formItem.scss'

export default function (props) {
  const { checkClass, isInputDisabled, column, row, onBlur, onFocus, onCheck } = props
  const [value, setValue] = useState('')

  const currentRow = row + 1
  const currentColumn = column + 1

  return <div className='excel-form-item'>
    <div className={checkClass}>
      <Checkbox onChange={() =>
        onCheck({ row: currentRow, column: currentColumn })}
      />
    </div>
    <Form.Item name={row + ',' + column}>
      <Input
        row={row}
        value={value}
        column={column}
        disabled={isInputDisabled}
        onChange={v => setValue(v.target.value)}
        onBlur={() => onBlur({ row: currentRow, column: currentColumn, value })}
        onFocus={() => onFocus({ row: currentRow, column: currentColumn, value })}
      />
    </Form.Item>
  </div>
}