/* eslint-disable */
import React from 'react'

import { Form, Input, Button } from 'antd';
import { validSame } from 'utils/validate'

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formRef: React.createRef()
        }
    }
    componentDidMount() {
        console.log(this.props)
        console.log(this.state)
        // const { setFieldsValue, getFieldValue } = this.props.form
        /*this.props.form.setFieldsValue({
            name: 'drivers'
        })*/
        this.state.formRef.current.setFieldsValue(this.props.form);
    }


    onFinish = (values) => {
        console.log('Success:', values);
        this.props.submitForm(values)
    };
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    checkSame=(rule, value, callback)=> {
        if (value) {
            console.log(rule, value, callback)
            console.log(this.state)
            console.log(this.props)
            if (!validSame(value, this.props.password)) {
                callback(new Error('两次输入密码不一致！'))
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    render() {
        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };
        const tailLayout = {
            wrapperCol: { offset: 4, span: 20 },
        };
        const validateMessages = {
      required: '${label}为必填项!',
      types: {
        email: '${label}格式不合法!',
        number: '${label}不是合法数字!',
      },
      number: {
        range: '${label}必须在${min}-${max}范围内',
      },
    };
        return <Form
            {...layout}
            ref={this.state.formRef}
            name="basic"
            initialValues={{is_closed: true}}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            validateMessages={validateMessages}
        >
            <Form.Item
                label="新密码"
                name='password'
                rules={[
                    {
                        required: true,
                        message: '请填写!',
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item
                label="确认密码"
                name='confirmPassword'
                rules={[
                    {
                        required: true,
                        message: '请填写!',
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            console.log(value)
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('两次输入密码不一致！!');
                        },
                    }),
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" className={'mR15'}>
                    {this.props.name==='resetPassword'?"重置密码":"修改"}
                </Button>
                <Button onClick={this.props.cancelForm}>
                    取消
                </Button>
            </Form.Item>
        </Form>

    }
}
