import React from 'react'

import { Input } from 'antd'

const { Search } = Input

export default function (props) {

  const onSearch = (value) => {
    console.log(value)
    props.onChange(value)
  }
  const onChange = (e) => {
    // props.onChange(e.target.value)
  }

  return <div className='flexWrap spaceBetween searchWrapper'>
    <div>
      {props.slot || props.children}
    </div>
    {/*<Search placeholder="请输入内容" onSearch={value => onSearch(value)} onChange={onChange}
            value={props.value} enterButton allowClear style={{width: '30%'}} loading={props.loading}/>*/}
    <Search
      placeholder="请输入内容"
      enterButton allowClear
      style={{ width: '30%' }}
      loading={props.loading}
      onChange={onChange}
      onSearch={value => onSearch(value)}
    />
  </div>
}