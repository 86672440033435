/* eslint-disable */
import React from 'react'
import { Table, DatePicker } from 'antd'

import EvenSearch from 'components/search'
import EvenPagination from 'components/pagination'

import { fetchUsers } from 'api/set/user'

import './index.scss'
import { getAuthInfo, noUndefined, } from 'utils'
import { fetchCells } from 'api/excelTable'

const { RangePicker } = DatePicker

export default class Set extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTableName: 'users',
      isSearchBtnLoading: false,
      isTableLoading: false,
      table: [],
      currentPage: 1,
      pageSize: 10,
      allCount: 0,
      currentId: null,
      bulkDeleteArr: [],
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
      addForm: {},
      detailForm: {},
      resetPasswordForm: {},

      modalTitle: '',
      modalVisible: false,
      groupVisible: false,
      modalContent: {
        addMark: false,
        markDetail: false,
        resetPassword: false
      },

      selectedRowKeys: [],

      statuses: '',
      min_created_time: '',
      max_created_time: '',

      users: [],
      selectedUsers: ''
    }
  }

  componentDidMount() {
    this.getList()

    const userId = getAuthInfo().id
    fetchUsers(userId).then(({ data }) => {
      const { results } = data
      for (const iterator of results) {
        iterator.text = iterator.nickname
        iterator.value = iterator.id
      }
      this.setState({ users: results })
    })
  }

  listQuery = () => {
    const { currentPage, pageSize, statuses, searchTxt, min_created_time, max_created_time, selectedUsers } = this.state
    return {
      page: currentPage,
      page_size: pageSize,
      search: searchTxt,
      status: statuses,
      account_id: selectedUsers,
      min_created_time,
      max_created_time
    }
  }
  getList = () => {
    return new Promise(resolve => {
      this.setState({
        isTableLoading: true
      })
      fetchCells(this.listQuery()).then(({ data }) => {
        let { results, count } = data
        for (const item of results) {
          noUndefined(item)
        }
        this.setState({
          isTableLoading: false,
          table: results,
          allCount: count,
        })
        resolve()
      })
    })
  }

  onSearchChange = (value) => {
    this.setState({
      isSearchBtnLoading: true,
      currentPage: 1,
      searchTxt: value,
    }, () => {
      this.getList().then(() => {
        this.setState({
          isSearchBtnLoading: false
        })
      })
    })
  }
  onShowSizeChange = (name, current, pageSize) => {
    this.setState({
      currentPage: 1,
      pageSize: pageSize
    }, () => {
      this.getList()
    })
  }
  onPageChange = (name, page) => {
    this.setState({
      currentPage: page
    }, () => {
      this.getList()
    })
  }

  handleTableChange(pagination, filters) {
    let statuses = '', selectedUsers = ''
    if (filters.status) {
      statuses = filters.status.join()
    }
    if (filters.account) {
      selectedUsers = filters.account.join()
    }
    this.setState({
      statuses,
      selectedUsers,
      currentPage: 1,
      searchTxt: ''
    }, () => {
      this.getList()
    })
  }

  handleRange(e) {
    const format = 'YYYY-MM-DD HH:mm:ss'
    let min = '', max = ''
    if (e) {
      min = e[0].format(format).slice(0, 11) + '00:00:00'
      max = e[1].format(format).slice(0, 11) + '23:59:59'
    }
    this.setState({
      min_created_time: min,
      max_created_time: max,
      currentPage: 1
    }, () => {
      this.getList()
    })
  }

  render() {

    const { isSearchBtnLoading, searchTxt, currentTableName, currentPage, users, allCount, isTableLoading, table } = this.state

    const columns = [
      {
        title: '上机板号',
        dataIndex: 'date_serial_number',
      },
      {
        title: '上机序号',
        dataIndex: 'order_serial_number',
      },
      {
        title: '样本号',
        dataIndex: 'number',
      },
      {
        title: '板位',
        dataIndex: 'point',
      },
      {
        title: '入库时间',
        dataIndex: 'storage_time',
      },
      {
        title: '入库状态',
        dataIndex: 'status',
        filters: [
          {
            text: '未入库',
            value: '10',
          },
          {
            text: '入库失败',
            value: '20',
          },
          {
            text: '已入库系统',
            value: '30',
          },
          {
            text: '已入库省平台',
            value: '40',
          },
        ]
      },
      {
        title: '账号',
        dataIndex: 'account',
        filters: users,
        filterSearch: true,
      },
    ]

    const { handleTableChange, onSearchChange, onShowSizeChange, onPageChange, handleRange } = this


    return <div className='mainWrapper user-root'>
      <EvenSearch
        loading={isSearchBtnLoading}
        value={searchTxt}
        onChange={onSearchChange}
      >
        <span>日期：</span>
        <RangePicker onChange={handleRange.bind(this)} />
      </EvenSearch>
      <Table
        tableLayout='fixed'
        loading={isTableLoading}
        rowKey='id'
        dataSource={table}
        columns={columns}
        pagination={false}
        onChange={handleTableChange.bind(this)}
      />
      <EvenPagination
        current={currentPage}
        total={allCount}
        onShowSizeChange={onShowSizeChange}
        onPageChange={onPageChange}
        name={currentTableName}
      />
    </div>
  }
}
