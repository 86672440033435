import React from 'react'
import ProgressBar from 'components/headerProgressBar'
import './App.scss'
import 'styles/index.scss'
import Sidebar from 'views/Layout/Sidebar'
import RightContent from 'views/Layout/RightContent'

export default function App() {
  return (
    <div className="App">
      <ProgressBar />
      <Sidebar />
      <RightContent />
    </div>
  )
}
