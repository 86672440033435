import { createStore } from 'redux'
import { initUploadObj } from 'utils/constant'

const authInfo = JSON.parse(localStorage.getItem('authInfo'))
let token = null
if (authInfo) {
  token = authInfo.token
}
/**
 * This is a reducer, a pure function with (state, action) => state signature.
 * It describes how an action transforms the state into the next state.
 *
 * The shape of the state is up to you: it can be a primitive, an array, an object,
 * or even an Immutable.js data structure. The only important part is that you should
 * not mutate the state object, but return a new object if the state changes.
 *
 * In this example, we use a `switch` statement and strings, but you can use a helper that
 * follows a different convention (such as function maps) if it makes sense for your
 * project.
 */
let defaultState = {
  componentKey: 0,
  counter: 0,
  timeStamp: 0,
  isSidebarCollapsed: false,
  currentLocation: '/',
  authInfo: JSON.parse(localStorage.getItem('authInfo')),
  isUploadExternalSampleFilesFormShow: false,
  isAuthenticated: token !== null,
  currentUploadObj: initUploadObj,
  isProgressBarLoading: false,
}

function counter(state = defaultState, action = {}) {
  const { type, param } = action
  switch (type) {
    case 'INCREMENT_COUNTER':
      return { ...state, counter: state.counter + 1 }
    case 'DECREMENT_COUNTER':
      return { ...state, counter: state.counter - 1 }
    case 'increase_component_key':
      return { ...state, componentKey: state.componentKey + 1 }
    case 'GET_TIME_STAMP':
      return { ...state, timeStamp: new Date().getTime() }
    case 'TOGGLE_SIDEBAR':
      localStorage.setItem('isCollapsed', !state.isSidebarCollapsed)
      return { ...state, isSidebarCollapsed: !state.isSidebarCollapsed }
    case 'showUploadSampleFile':
      return { ...state, isUploadExternalSampleFilesFormShow: true }
    case 'hideUploadSampleFile':
      return { ...state, isUploadExternalSampleFilesFormShow: false }
    case 'CHANGE_UPLOAD_OBJ':
      //{ ...state.currentUploadObj, ...param }
      return { ...state, currentUploadObj: param }
    case 'GET_CURRENT_LOCATION':
      return { ...state, currentLocation: window.location.pathname }
    case 'signOut':
      // localStorage.removeItem('authInfo')
      return { ...state, isAuthenticated: false }
    case 'authenticate':
      return { ...state, isAuthenticated: true }
    case 'toggleProgressBarLoading':
      return { ...state, isProgressBarLoading: param }
    default:
      return { ...state, ...defaultState }
  }

}

// Create a Redux store holding the state of your app.
// Its API is { subscribe, dispatch, getState }.
let store = createStore(counter)

// You can use subscribe() to update the UI in response to state changes.
// Normally you'd use a view binding library (e.g. React Redux) rather than subscribe() directly.
// However it can also be handy to persist the current state in the localStorage.
// store.subscribe(() => console.log(store.getState()))
let currentValue
store.subscribe(() => {
  let storeObj = store.getState()
  let previousValue = currentValue
  // currentValue = select(storeObj)
  currentValue = storeObj

  if (previousValue) {
    for (const [key, value] of Object.entries(storeObj)) {
      // console.log(`${key}: ${value}`);
      // console.log('previousValue[key], value', previousValue[key], value)
      if (previousValue[key] !== value) {

        console.log(
          `${key} changed from`,
          { [key]: previousValue[key] },
          'to',
          { [key]: value }
        )
      }
    }
  } else {
    console.log(
      `store changed from`,
      previousValue,
      'to',
      currentValue
    )
  }

  /*if (previousValue !== currentValue) {
    console.log(
        'Some deep nested property changed from',
        previousValue,
        'to',
        currentValue
    )
  }*/

  /*console.log('state状态改变了，新状态如下')
  console.log(store.getState())*/
})
// The only way to mutate the internal state is to dispatch an action.
// The actions can be serialized, logged or stored and later replayed.
// store.dispatch({ type: 'INCREMENT_COUNTER' })
// 1
// store.dispatch({ type: 'INCREMENT_COUNTER' })
// 2
// store.dispatch({ type: 'DECREMENT_COUNTER' })
// 1
export default store
