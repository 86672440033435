import React, { useState, useEffect, useRef } from 'react'
import { NProgress } from '@tanem/react-nprogress'
import Container from './Container'
import Bar from './Bar'
import { useLocation } from 'react-router-dom'

export default function (props) {
  let location = useLocation()
  const [isLoading, setLoading] = useState(false)
  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => { ref.current = value })
    return ref.current
  }
  const useLocationChange = (action) => {
    const location = useLocation()
    const prevLocation = usePrevious(location)
    useEffect(() => {
      setLoading(true)
      action(location, prevLocation)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
  }
  useLocationChange((location, prevLocation) => {
    setTimeout(() => {
      setLoading(false)
    }, 100)
  })

  return <NProgress isAnimating={isLoading} key={location.pathname}>
    {({ isFinished, progress, animationDuration }) => (
      <Container
        isFinished={isFinished}
        animationDuration={animationDuration}
      >
        <Bar
          progress={progress}
          animationDuration={animationDuration}
        />
        {/*
                    This example doesn't use a spinner component so the UI stays
                    tidy. You're free to render whatever is appropriate for your
                    use-case.
                    */}
      </Container>
    )}
  </NProgress>
}
