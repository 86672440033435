/* eslint-disable */
import React from 'react'
import { Button, Table, message } from 'antd'

import EvenSearch from 'components/search'
import EvenPagination from 'components/pagination'
import EvenModal from 'components/modal'

import UserForm from './components/userForm'
import ResetPasswordForm from './components/resetPasswordForm'

import { responseMessage, confirmMessage } from 'utils/index'
import { PlusOutlined } from '@ant-design/icons'
import { modifyUserByPatch, fetchSystemUsers, addUser, bulkDeleteUsers, userResetPassword, userActivation, fetchUserDetail } from 'api/set/user'

import './index.scss'
import { base64UrlToBlob, confirmSingleDelete, noUndefined, } from 'utils'
import { validPhone, validEmail } from 'utils/validate'
import GroupTable from './components/groupTable'

export default class Set extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTableName: 'users',
      isSearchBtnLoading: false,
      isTableLoading: false,
      table: [],
      currentPage: 1,
      pageSize: 10,
      allCount: 0,
      currentId: null,
      bulkDeleteArr: [],
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
      addForm: {},
      detailForm: {},
      resetPasswordForm: {},

      modalTitle: '',
      modalVisible: false,
      groupVisible: false,
      modalContent: {
        addMark: false,
        markDetail: false,
        resetPassword: false
      },

      selectedRowKeys: []
    }
  }

  componentDidMount() {
    this.getList()
  }

  listQuery = () => {
    return {
      page: `${this.state.currentPage}`,
      page_size: `${this.state.pageSize}`,
      search: `${this.state.searchTxt}`,
    }
  }
  getList = () => {
    return new Promise(resolve => {
      this.setState({
        isTableLoading: true
      })
      fetchSystemUsers(this.listQuery()).then(({ data }) => {
        let { results, count } = data
        for (const item of results) {
          noUndefined(item)
        }
        this.setState({
          isTableLoading: false,
          table: results,
          allCount: count,
        })
        resolve()
      })
    })
  }
  getDetail = () => {
    return new Promise(resolve => {
      fetchUserDetail(this.state.currentId).then(response => {
        this.setState({
          detailForm: response.data
        })
        resolve()
      })
    })
  }

  onSearchChange = (value) => {
    this.setState({
      isSearchBtnLoading: true,
      currentPage: 1,
      searchTxt: value,
    }, () => {
      this.getList().then(() => {
        this.setState({
          isSearchBtnLoading: false
        })
      })
    })
  }
  onShowSizeChange = (name, current, pageSize) => {
    this.setState({
      currentPage: 1,
      pageSize: pageSize
    }, () => {
      this.getList()
    })
  }
  onPageChange = (name, page) => {
    this.setState({
      currentPage: page
    }, () => {
      this.getList()
    })
  }
  clickAddBtn = () => {
    this.initModal()
    this.initForm()

    this.setState({
      modalVisible: true,
      modalTitle: '新增用户',
      modalContent: {
        addMark: true
      },
    })
  }
  initModal = () => {
    this.setState({
      groupVisible: false,
      modalContent: {
        addMark: false,
        markDetail: false,
        resetPassword: false
      },
    })
  }
  initForm = () => {
    this.setState({
      addForm: {},
      detailForm: {},
    })
  }
  submitEdit(value) {
    const key = Object.keys(value)[0]
    const val = Object.values(value)[0]
    if (key === 'phone') {
      if (!validPhone(val)) return
    }
    if (key === 'email') {
      if (!validEmail(val)) return
    }
    if (key === 'avatar') {
      value = new FormData()
      value.append('avatar', base64UrlToBlob(val))
    }
    modifyUserByPatch(this.state.currentId, value).then(response => {
      this.getList()
      message.success('修改成功！')
    }, error => {
      console.log(error.response)
    })
  }
  submitForm = (name, value) => {
    switch (name) {
      case 'add':
        let submitData = value
        addUser(submitData).then(response => {
          this.getList()
          message.success('创建成功！')
          this.setState({
            modalVisible: false
          })
        }, error => {
          console.log(error.response)
        })
        break
      case 'detail':
        this.submitEdit(value)
        this.setState({ modalVisible: false })
        break
      case 'resetPasswordForm':
        userResetPassword(this.state.currentId, value.password).then(response => {
          message.success('重置成功！')
          this.getList()
          this.setState({
            modalVisible: false
          })
        }, error => {
          console.log(error.response)
        })
        break
      default:
    }
  }
  cancelForm = (name, value) => {
    this.setState({
      modalVisible: false
    })
  }
  cancelModal = () => {
    this.setState({
      modalVisible: false
    })
  }
  submitModal = () => {
    this.setState({
      modalVisible: false
    })
  }
  clickDropdown = (name, text, record, e) => {
    console.log(name, text, record, e)
    e.stopPropagation()

    this.setState({
      currentId: text.id,
    })
  }
  clickDropdownItem = (value, id) => {
    this.setState({ currentId: id }, () => {
      const { currentId } = this.state
      let func
      switch (value) {
        case 'delete':
          confirmSingleDelete(this, 'system_users/', currentId)
          break
        case 'forbid':
          func = () => {
            userActivation(this.state.currentId, { is_active: false }).then(response => {
              message.success('已禁用！')
              this.setState({
                currentPage: 1
              }, () => {
                this.getList()
              })
            })
          }
          confirmMessage(func, '确定禁用该用户？', '已取消禁用')
          break
        case 'active':
          func = () => {
            userActivation(this.state.currentId, { is_active: true }).then(response => {
              message.success('已激活！')
              this.setState({
                currentPage: 1
              }, () => {
                this.getList()
              })
            })
          }
          confirmMessage(func, '确定激活该用户？', '已取消激活')
          break
        case 'resetPassword':
          this.setState({
            modalVisible: true
          })
          this.initModal()
          this.setState({
            modalTitle: '重置密码',
            modalContent: {
              resetPassword: true
            }
          })
          break
        default:
      }
    })
  }
  checkRow = (id) => {
    this.setState({
      currentId: id,
      modalVisible: true
    }, () => {
      this.getDetail().then(() => {
        this.setState({
          modalContent: {
            markDetail: true
          }
        })
      })
    })
    this.initModal()
    this.setState({
      modalTitle: '用户详情'
    })
  }
  clickBulkDeleteBtn = (tableName, e) => {
    e.stopPropagation()
    let params, func
    switch (tableName) {
      case 'users':
        params = {
          deleted_objects: this.state.selectedRowKeys
        }
        func = () => {
          bulkDeleteUsers(params).then(response => {
            console.log(response)
            responseMessage(response)
            this.setState({
              currentPage: 1,
              selectedRowKeys: []
            }, () => {
              this.getList()
            })
          })
        }
        confirmMessage(func, '是否批量删除？')
        break
      default:
    }
  }

  tabClick = (key, event) => {
    console.log(key, event)
    this.setState({
      currentTableName: key,
      currentPage: 1,
      pageSize: null,
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
    }, () => {
      this.getList()
    })
  }

  openGroups() {
    this.initModal()
    this.setState({
      modalTitle: '用户组设置',
      modalVisible: true,
      groupVisible: true
    })
  }

  render() {
    const usersColumns = [
      {
        title: '账号',
        dataIndex: 'username',
      },
      {
        title: '用户名',
        dataIndex: 'nickname',
      },
      {
        title: '联系方式',
        dataIndex: 'phone',
      },
      {
        title: '操作',
        dataIndex: 'id',
        render: (text, record) => (
          <div className='operation-column'>
            <a onClick={() => this.checkRow(text)}>详情</a>
            <a onClick={() => this.clickDropdownItem('delete', text)}>删除</a>
            {record.is_active &&
              <a onClick={() => this.clickDropdownItem('forbid', text)}>
                禁用
              </a>}
            {!record.is_active &&
              <a onClick={() => this.clickDropdownItem('active', text)}>
                激活
              </a>}
            <a onClick={() => this.clickDropdownItem('resetPassword', text)}>重置密码</a>
          </div>
        ),
      },
    ]

    const { isSearchBtnLoading, searchTxt, currentTableName, currentPage, allCount, modalContent, modalTitle, groupVisible, modalVisible, addForm, detailForm, resetPasswordForm } = this.state
    const modalWidth = modalTitle === '用户组设置' ? '90%' : '70%'

    return <div className='mainWrapper user-root'>
      <EvenSearch
        loading={isSearchBtnLoading}
        value={searchTxt}
        onChange={this.onSearchChange}
        slot={<div className='btn-w'>
          <Button
            type="primary"
            size='middle'
            icon={<PlusOutlined />}
            onClick={() => this.clickAddBtn(currentTableName)}
          >
            新增
          </Button>
          <Button
            size='middle'
            onClick={this.openGroups.bind(this)}
          >
            用户组
          </Button>
        </div>
        } />
      <Table
        tableLayout='fixed'
        loading={this.state.isTableLoading}
        rowKey='id'
        dataSource={this.state.table}
        columns={usersColumns}
        pagination={false}
        rowClassName={(record) => !record.is_active && 'disabled-user'}
      />
      <EvenPagination
        current={currentPage}
        total={allCount}
        onShowSizeChange={this.onShowSizeChange}
        onPageChange={this.onPageChange}
        name={currentTableName}
      />

      <EvenModal
        className='user-modal'
        title={modalTitle}
        visible={modalVisible}
        cancel={this.cancelModal}
        submit={this.submitModal}
        width={modalWidth}
        slot={
          <div>
            {modalContent.addMark &&
              <UserForm
                name={'add'}
                form={addForm}
                submitForm={(value) => { this.submitForm('add', value) }}
                cancelForm={this.cancelForm}
              />}
            {modalContent.markDetail &&
              <UserForm
                name={'detail'}
                form={detailForm}
                submitForm={(value) => { this.submitForm('detail', value) }}
                cancelForm={this.cancelForm}
              />}
            {modalContent.resetPassword &&
              <ResetPasswordForm
                name={'resetPassword'}
                form={resetPasswordForm}
                submitForm={(value) => { this.submitForm('resetPasswordForm', value) }}
                cancelForm={this.cancelForm}
              />}
            {groupVisible && <GroupTable />}
          </div>
        } />
    </div>
  }
}
