import User from 'views/User'
import OnBoardInquiry from 'views/OnBoardInquiry'
import OnBoardStatistics from 'views/OnBoardStatistics'
import CheckIn from 'views/CheckIn'
import Login from 'views/Login/index'
import Sample from '../views/Sample'
import ErrorPage from '../views/ErrorPage/index'

const links = [
  {
    url: '/login',
    title: '登录',
    hidden: true,
  },
  {
    url: '/404',
    title: '错误页面',
    hidden: true,
  },
  {
    url: '/',
    title: '样本管理',
  },
  // {
  //   url: '/checkIn',
  //   title: '上机登记',
  // },
  // {
  //   url: '/OnBoardInquiry',
  //   title: '上机查询',
  // },
  // {
  //   url: '/statistics',
  //   title: '上机统计',
  // },
  {
    url: '/users',
    title: '用户管理',
  },
]

const routeComponents = [
  { path: '/login', component: Login },
  { path: '/404', component: ErrorPage },
  { path: '/users', component: User },
  { path: '/OnBoardInquiry', component: OnBoardInquiry },
  { path: '/checkIn', component: CheckIn },
  { path: '/statistics', component: OnBoardStatistics },
  { path: '/', component: Sample },
]


export { links, routeComponents }
