import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Dropdown, Form, Input, message, Modal } from 'antd'
import { DownOutlined, } from '@ant-design/icons'
import './index.scss'
import LogTable from 'components/log'
import { userResetPassword } from 'api/set/user'

export default function Header(props) {

  const [name, setName] = useState('')
  const [logVisible, setLogVisible] = useState(false)
  const [pwdVisible, setPwdVisible] = useState(false)

  const authInfo = JSON.parse(localStorage.getItem('authInfo'))
  let history = useHistory()
  let location = useLocation()

  useEffect(() => {
    if (authInfo) {
      setName(authInfo.username)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const menuClick = value => {
    const { key } = value
    switch (key) {
      case 'logout':
        localStorage.removeItem('authInfo')
        localStorage.removeItem('isCollapsed')
        history.push("/login", { from: location })
        break
      case 'accountLog':
        setLogVisible(true)
        break
      case 'changePwd':
        setPwdVisible(true)
        break
      default:
    }
  }

  const submit = v => {
    const { password, newPwd } = v
    if (newPwd !== password) {
      message.error('两次密码输入不一致！')
      return
    }
    userResetPassword(authInfo.id, password).then(res => {
      message.success('重置成功！')
      setPwdVisible(false)
    }, error => {
      console.log(error.response)
    })
  }

  const menu = {
    items: [
      { key: 'accountLog', label: '账号日志' },
      { key: 'changePwd', label: '修改密码' },
      { key: 'logout', label: '退出登录' },
    ],
    onClick: menuClick
  }

  return <header className='header'>
    <div className='headerMainWrapper flexWrap alignItems spaceBetween'>
      <div className='flexWrap alignItems' />

      <div className='flexWrap alignItems'>
        <Dropdown
          menu={menu}
          trigger={['click']}
          className='mL15'
        >
          {/*eslint-disable-next-line*/}
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {name}
            {' '}
            <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
    {/* 顶部下划线 */}
    <div className='tagsWrapper flexWrap alignItems' />
    <Modal
      title='修改密码'
      open={pwdVisible}
      footer={null}
      onCancel={() => setPwdVisible(false)}
    >
      <Form labelCol={{ span: 4 }} onFinish={submit}>
        <Form.Item
          label='新密码'
          name='newPwd'
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item label='确认密码'
          name='password'
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 4,
          }}
        >
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
    <Modal
      title='账号日志'
      width='80%'
      open={logVisible}
      footer={null}
      onCancel={() => setLogVisible(false)}
      onOk={() => setLogVisible(false)}
    >
      <LogTable whichLog='accountLog' />
    </Modal>
  </header>
}
