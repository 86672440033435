import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import App from './App'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import Login from 'views/Login/index'
import ErrorPage from 'views/ErrorPage/index'
import { ConfigProvider } from 'antd'
import store from "./store"

moment.locale('zh-cn')

let storeObj = store.getState()
let isAuthenticated = storeObj.isAuthenticated

store.subscribe(() => {
  let storeObj = store.getState()
  isAuthenticated = storeObj.isAuthenticated
})

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
let PrivateRoute = ({ children, ...rest }) => {
  const renderFn = ({ location }) => {
    return isAuthenticated ?
      children :
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
  }

  return <Route
    {...rest}
    render={renderFn}
  />
}

ReactDOM.render(
  <Router>
    <Switch>
      <Route path='/login' component={Login} />
      <Route path='/404' component={ErrorPage} />
      <PrivateRoute path="/">
        <ConfigProvider locale={zhCN}>
          <App />
        </ConfigProvider>
      </PrivateRoute>
    </Switch>
  </Router>,
  document.getElementById('root')
)
