/* eslint-disable */
import React from 'react'
import { Table, DatePicker, Divider } from 'antd'

import './index.scss'
import { getLastInMonth } from 'utils'
import { fetchCellChartsData, fetchCellList } from 'api/onBoardStatistics'
import { dateTimeFormat } from 'utils/constant'
import moment from 'moment'
import { Line } from '@ant-design/charts'

const { RangePicker } = DatePicker
const { Summary } = Table

const columns = [
  {
    title: '账号',
    dataIndex: 'nickname',
  },
  {
    title: '单检',
    dataIndex: '1',
  },
  {
    title: '10混1',
    dataIndex: '10',
  },
  {
    title: '20混1',
    dataIndex: '20',
  },
  {
    title: '总量',
    dataIndex: 'total',
  },
]

const today = new Date().toISOString().slice(0, 10)
const firstOfMonth = today.slice(0, 8) + '01'
const lastDayInMonth = today.slice(0, 8) + getLastInMonth()

export default class Set extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSearchBtnLoading: false,
      isTableLoading: false,
      table: [],
      currentPage: 1,
      pageSize: 10,
      currentId: null,
      bulkDeleteArr: [],
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
      addForm: {},
      detailForm: {},
      resetPasswordForm: {},

      selectedRowKeys: [],

      statuses: '',

      pickedDate: moment(today),
      total_1: 0,
      total_10: 0,
      total_20: 0,
      total_all: 0,

      min_created_time: moment(firstOfMonth),
      max_created_time: moment(today),

      chartsData: []
    }
  }

  componentDidMount() {
    const { pickedDate } = this.state

    const todayStr = pickedDate.format(dateTimeFormat).slice(0, 10)
    this.getList(todayStr)

    this.getChartsData(firstOfMonth, today)
  }

  getChartsData(firstDate, lastDate) {
    fetchCellChartsData(firstDate, lastDate + ' 23:59:59')
      .then(res => {
        const { status, data } = res
        if (status !== 200) return

        this.setState({ chartsData: data })
      })
  }

  getList = date => {
    this.setState({
      isTableLoading: true
    })
    fetchCellList(date).then(({ data }) => {
      const { total_1, total_10, total_20, total_all } = data.total
      this.setState({
        isTableLoading: false,
        table: data.data,
        total_1,
        total_10,
        total_20,
        total_all,
      })
    })
  }

  handleDatePicker(e) {
    this.setState({ pickedDate: e })
    const pickedDate = e.format(dateTimeFormat).slice(0, 10)
    this.getList(pickedDate)
  }


  handleRange(e) {
    const min = e[0].format(dateTimeFormat).slice(0, 10)
    const max = e[1].format(dateTimeFormat).slice(0, 10)
    this.setState({
      min_created_time: e[0],
      max_created_time: e[1]
    })
    this.getChartsData(min, max)
  }

  render() {
    const { isSearchBtnLoading, searchTxt, max_created_time, min_created_time, pickedDate, isTableLoading, table, total_1, total_10, total_20, total_all, chartsData } = this.state

    const { handleDatePicker, handleRange } = this

    const summaryTr = () => table.length ?
      <Summary.Row>
        <Summary.Cell>总计</Summary.Cell>
        <Summary.Cell>{total_1}</Summary.Cell>
        <Summary.Cell>{total_10}</Summary.Cell>
        <Summary.Cell>{total_20}</Summary.Cell>
        <Summary.Cell>{total_all}</Summary.Cell>
      </Summary.Row>
      : undefined

    const config = {
      data: chartsData,
      xField: 'created_time',
      yField: 'total',
      seriesField: 'nickname',
      xAxis: {
        type: 'time',
      },
      legend: {
        position: 'bottom'
      },
      tooltip: {
        formatter: datum => {
          const { nickname, total, created_time } = datum

          for (const iterator of chartsData) {
            const isNicknameSame = iterator.nickname === nickname
            const isCreatedTimeSame = iterator.created_time === created_time

            if (isNicknameSame && isCreatedTimeSame) {
              const value = `单检：${iterator['1']}，10混：${iterator['10']}，
              20混：${iterator['20']}，总计：${total}`
              return { name: nickname, value }
            }
          }
        },
      }
    }

    return <div className='mainWrapper onBoard-statistics'>
      <span>日期：</span>
      <RangePicker
        allowClear={false}
        value={[min_created_time, max_created_time]}
        onChange={handleRange.bind(this)}
      />

      <Line {...config} />

      <Divider />

      <span>日期：</span>
      <DatePicker
        allowClear={false}
        value={pickedDate}
        onChange={handleDatePicker.bind(this)}
      />
      <Table
        tableLayout='fixed'
        loading={isTableLoading}
        rowKey='nickname'
        dataSource={table}
        columns={columns}
        pagination={false}
        summary={summaryTr}
      />
    </div>
  }
}
