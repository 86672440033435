/* eslint-disable */
import React from 'react'
import { Form, Input, Button, Select } from 'antd'
import { validateMessages, tailLayout, layout } from 'utils/index'
import { validPhone } from 'utils/validate'
import { requiredRule } from 'utils'
import { fetchGroups } from 'api/set/users/group'

const { Option } = Select

export default class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formRef: React.createRef(),
      company: '',
      companiesList: [],
      departmentsList: [],
      groups: [],
      isDepartmentDisabled: true,

      avatarUrl: ''
    }
  }

  componentDidMount() {
    this.state.formRef.current.setFieldsValue(this.props.form)
    fetchGroups().then(({ data }) => {
      this.setState({ groups: data.results })
    })
  }

  getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.results))
    reader.readAsDataURL(img)
  }

  changeAvatar(info) {
    const { fileList } = info
    const index = fileList.length - 1
    this.getBase64(
      fileList[index].originFileObj,
      avatarUrl => {
        // this.state.formRef.current.setFieldsValue({ avatar: avatarUrl })
        // this.props.submitForm({ avatar: avatarUrl })
        this.setState({
          avatarUrl,
          loading: false,
        })
      }
    )
  }

  handleChange = (value) => {
    this.setState({
      company: value,
      isDepartmentDisabled: false
    }, () => {
      this.getDepartmentsList()
      this.state.formRef.current.setFieldsValue({
        department: [],
      })
    })
  }

  onFinish = (values) => {
    this.props.submitForm(values)
  };

  render() {
    const { formRef, groups } = this.state
    const groupOptions = groups.map(i =>
      <Option key={i.id} value={i.id}>{i.name}</Option>)

    return <Form
      className='user-form'
      {...layout}
      ref={this.state.formRef}
      name="basic"
      initialValues={{ is_closed: true }}
      onFinish={this.onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        label="账号"
        name='username'
        rules={requiredRule()}
      >
        <Input disabled={this.props.name === 'detail'} />
      </Form.Item>
      <Form.Item
        label="用户名"
        name='nickname'
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="手机号码"
        name='phone'
        rules={[() => ({
          validator(rule, value) {
            if (!value) return Promise.resolve()
            if (validPhone(value)) {
              return Promise.resolve()
            }
            return Promise.reject('手机号码格式不正确!')
          },
        })
        ]}
      >
        <Input />
      </Form.Item>
      {this.props.name === 'add' &&
        <Form.Item
          label="密码"
          name='password'
          rules={requiredRule()}
        >
          <Input.Password />
        </Form.Item>}
      <Form.Item
        label='角色'
        name='role'
        rules={[{
          required: true,
        }]}
      >
        <Select>
          <Option value={2}>内部用户</Option>
          <Option value={3}>外部用户</Option>
        </Select>
      </Form.Item>
      <Form.Item label='用户组' name='groups'>
        <Select mode="multiple">
          {groupOptions}
        </Select>
      </Form.Item>

      <Form.Item {...tailLayout}>
        {this.props.name === 'add' ?
          <Button type="primary" htmlType="submit" className={'mR15'}>
            创建
          </Button> :
          <Button className={'mR15'} type='primary' htmlType="submit">
            确定
          </Button>}
        <Button onClick={this.props.cancelForm}>
          取消
        </Button>
      </Form.Item>
    </Form>
  }
}
